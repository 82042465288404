import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Card, Button } from 'antd';
import StepForm from './StepForm';
import {
  logUserOut
} from '../../actions';
import './JoinCompany.css'
import CalendarWrite from '../auth/CalendarWrite';
import logo from '../../images/mlogo.png';

class JoinCompany extends Component {

  state = { show_step_form: false };

  onLogout() {
    this.props.logUserOut(() => {
      const history = createBrowserHistory();
      history.push('/');
      window.location.reload();
    });
  }

  renderContent() {
    if (this.state.show_step_form) {
      return (
        <Card
          style={this.getModalStyle()}
        >
          <StepForm />
        </Card>
      );
    } else {
      const cardStyle = { width: "100%", paddingLeft: "40px", paddingRight: "40px" };
      return (
        <Card
          style={this.getModalStyle()}
        >
          <Card.Grid style={cardStyle} hoverable={false}>
            <div className="JoinCompany-intro-section">
              <div className="header_style">Create a new MeetLoaf group for your company or team!</div>
              <Button size="large" type="primary" onClick={() => this.setState({ show_step_form: true })}>
                + Create Group
              </Button>
            </div>
          </Card.Grid>
          <Card.Grid style={cardStyle} hoverable={false}>
            <div className="JoinCompany-sub-intro-section">
              <div className="sub_header_style">Does your company or team already have a MeetLoaf group?</div>
              <h3>Contact your administrator for an invite.</h3>
            </div>
          </Card.Grid>
        </Card>
      );
    }
  }

  getModalStyle() {
    if (this.state.show_step_form) {
      return {
        height: "500px",
        marginTop: "50px", width: "100%", maxWidth: "800px"
      };
    } else {
      return {
        maxHeight: "500px",
        marginTop: "50px", width: "100%", maxWidth: "800px"
      };
    }
  }

  render() {
    return (
      <div className="JoinCompany-card-modal" style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: 'column' }}>
        <img src={logo} alt="logo" style={{ width: "240px", marginTop: "50px" }} />
        <Button type="link" style={{ position: "absolute", right: 0, top: 0 }} onClick={() => this.onLogout()}>Logout</Button>
        {this.renderContent()}
      </div>
    );
  }
}

export default connect(null, {
  logUserOut
})(JoinCompany);
