import React, { Component } from 'react';
import { Select, DatePicker } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import './BusinessSettings.css';
import {
  getRequest
} from '../../actions';
const { Option } = Select;
const { MonthPicker } = DatePicker;

class UserInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      departments: [],
      loadingDepartments: true
    }
    if (props.departmentId && props.departmentName) {
      this.state.departments = [{ id: props.departmentId, name: props.departmentName }];
      this.state.loadingDepartments = false;
    }
  }

  onDepartmentChanged(e) {
    this.props.onDepartmentChanged(e);
  }

  onJoinDateChanged(e) {
    this.props.onJoinDateChanged(e);
  }

  onBirthdateChanged(e) {
    this.props.onBirthdateChanged(e);
  }

  componentDidMount() {
    this.props.getRequest("departments/list", null, (response) => {
      this.setState({ loadingDepartments: false, departments: response.departments });
    })
  }

  getDepartmentSelector() {
    return (
      <Select
        style={{ width: "100%" }}
        value={this.props.departmentId}
        showSearch
        optionFilterProp="label"
        placeholder="Select department"
        onChange={(e) => this.onDepartmentChanged(e)}
      >
        {
          this.state.departments.map((department) => {
            return <Option value={department.id} key={department.id} label={department.name}>{department.name}</Option>;
          })
        }
      </Select>
    )
  }

  render() {
    return (
      <div>
      { !this.state.loadingDepartments && this.state.departments.length > 0 ?
        <div style={{ }}>
          <div className="JoinCompany-steps-field-header">{this.props.questionLabel ? "Select your department" : "Department"}</div>
          <div className="mono-font">
            {this.getDepartmentSelector()}
          </div>
        </div> :
        null
      }
      { this.props.hideBday ?
        null :
        <>
        <div style={{ marginTop: "20px" }}>
          <div className="JoinCompany-steps-field-header">{this.props.questionLabel ? `When did you join ${this.props.company.name}?` : "Join Date"}</div>
          <div className="mono-font">
            <MonthPicker
              format={"MM/YYYY"}
              disabledDate={d => !d || d.isAfter(moment())}
              value={this.props.joinDate}
              onChange={(date) => {
                this.onJoinDateChanged(date);
              }}
            />
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
        <div className="JoinCompany-steps-field-header">{this.props.questionLabel ? "When is your birthday?" : "Birthday"}</div>
          <div className="mono-font">
            <DatePicker
              disabledDate={d => !d || d.isAfter(moment())}
              format={"MM/DD/YYYY"}
              value={this.props.birthdate}
              onChange={(date) => {
                this.onBirthdateChanged(date);
              }}
            />
          </div>
        </div>
        </>
      }
      </div>
    );
  }
}


const mapStatesToProps = (state) => {
  const { company } = state.company;
  return { company };
};

export default connect(mapStatesToProps, {
  getRequest
})(UserInfo);
