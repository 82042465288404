import React, { Component } from 'react';
import { Input } from 'antd';
import '../App.css';
import '../home/company/BusinessSettings.css';

class InputField extends Component {
  state = { text_value: this.props.text_value }

  renderLabel() {
    if (this.props.label) {
      return <div className="BusinessSettings-content-header" style={{ marginBottom: "6px" }}>{this.props.label}</div>;
    }
  }

  render() {
    return (
      <div style={{ marginTop: "20px" }}>
        {this.renderLabel()}
        <Input
          type={this.props.is_password ? "password" : "text"}
          className={`App-input-field`}
          style={{ maxWidth: this.props.maxWidth ? this.props.maxWidth : "300px", fontSize: "16px" }}
          placeholder={this.props.placeholder}
          value={this.props.text_value}
          onChange={(event) => this.props.onUpdate(event)}
          onFocus={() => {
            this.props.onFocus()
          }}
        />
      </div>
    );
  }
}

export default InputField;
