import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Modal, Input, Checkbox, Row, Col } from 'antd';
import { validateEmail } from '../../../global_vars';
import {
  getRequest,
  postRequest,
  setDepartments
} from '../../../actions';
const NEW_DEPARTMENT = "NEW_DEPARTMENT";

class EditDepartment extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    console.log(props)
    if (props.departmentToEdit) {
      this.state = {
        name: props.departmentToEdit.name,
        id: props.departmentToEdit.id
      }
      let blacklist = props.departmentToEdit.department_blacklist;
      let whitelist = _.difference(_.map(props.company.departments, "id"), blacklist);
      this.state.whitelist = whitelist;
    } else {
      let whitelist = [NEW_DEPARTMENT].concat(_.map(props.company.departments, "id"));
      this.state.whitelist = whitelist;
    }
  }
  shouldBeEnabled() {
    return (
      this.state.name && this.state.name.length > 0
    );
  }

  getBlacklist() {
    let isNew = !this.props.departmentToEdit
    let allDepartments = _.map(this.props.company.departments, "id");
    let whitelist = this.state.whitelist;
    if (isNew) {
      console.log("NEW ONE");
      allDepartments = allDepartments.concat([NEW_DEPARTMENT]);
    }
    return _.difference(allDepartments, whitelist);
  }

  componentDidMount() {

  }

  handleSubmit() {
    this.setState({ is_loading: true })
    this.props.postRequest("departments/update", {
      departments: [
        {
          id: this.state.id,
          name: this.state.name,
          department_blacklist: this.getBlacklist()
        }
      ]
    }, (response) => {
      if (response.departments) {
        this.setState({ is_loading: false, name: "" });
        this.props.onSuccess();
        this.props.setDepartments(response.departments);
      }
      this.props.onSuccess()
    });
  }

  handleDepartmentNameChange(event) {
    this.setState({ name: event.target.value });
  }

  renderLoginError() {
    if (this.props.login_error != null) {
      return (
        <div style={{ color: 'red', paddingTop: '20px' }}>
          {this.props.login_error}
        </div>
      );
    }
  }

  onChangeWhitelist(checkedValues) {
    this.setState({ whitelist: checkedValues });
  }

  renderMatchRules() {
    return (
      <Checkbox.Group style={{ width: '100%' }} value={this.state.whitelist} onChange={(vals) => this.onChangeWhitelist(vals)}>
        <Row>
          {
            this.state.id ?
            null :
            <Col span={8} key={NEW_DEPARTMENT}>
              <Checkbox value={NEW_DEPARTMENT}>
                {this.state.name}
              </Checkbox>
            </Col>
          }
          {
            (this.props.company.departments || []).map((department) => {
              return (
                <Col span={8} key={department.id}>
                  <Checkbox value={department.id}>{
                    department.id === this.state.id ?
                    this.state.name : department.name}
                  </Checkbox>
                </Col>
              )
            })
          }
        </Row>
      </Checkbox.Group>
    );
  }

  onCancel() {
    this.setState({ email: "" });
    this.props.onCancel();
  }

  render() {
    return (
      <Modal
        title={this.props.name ? "Edit Department" : "Add Department"}
        visible={this.props.visible}
        onOk={() => this.handleSubmit()}
        onCancel={() => this.onCancel()}
        okButtonProps={{ loading: this.state.is_loading, disabled: !this.shouldBeEnabled() }}
        okText="Submit"
      >
        <div style={{ marginBottom: 20 }}>
          <label>
            Department Name: <br/>
            <Input style={{ width: '300px' }} type="text" value={this.state.name} onChange={(e) => this.handleDepartmentNameChange(e)} />
          </label>
        </div>
        <div>
          <label>
            Members of this department can match with members of these departments: <br />
            {this.renderMatchRules()}
          </label>
        </div>
        {this.renderLoginError()}
      </Modal>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user, login_error } = state.account;
  const { company } = state.company;
  return { user, company, login_error };
}

export default connect(mapStatesToProps, {
  getRequest,
  postRequest,
  setDepartments
})(EditDepartment);
