import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'antd';
import { UserAgentApplication } from 'msal';
import msSignup from '../images/ms_signup.svg';
import { msRedirect } from '../global_vars';

import {
  registerUserWithGoogle,
  setLoginError,
  postRequest
} from '../actions';

const scopes = [
    'user.read',
    'calendars.read'
  ];

class MicrosoftButton extends Component {

  constructor(props) {
    super(props);

    this.userAgentApplication = new UserAgentApplication({
      auth: {
          clientId: "9bf4f644-14f4-469b-9d3f-09ddfb263f0a",
          redirectUri: "http://localhost:3000"
      }
    });

    var user = this.userAgentApplication.getAccount();

    this.state = {
      isAuthenticated: (user !== null),
      user: {},
      error: null
    };

    if (user) {
      // Enhance user object with data from Graph
      this.getUserProfile();
    }
  }

  testReq() {
    window.location = `
      https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
      client_id=9bf4f644-14f4-469b-9d3f-09ddfb263f0a
      &response_type=code
      &redirect_uri=${encodeURI(msRedirect)}
      &response_mode=query
      &scope=${encodeURI("openid offline_access profile email https://graph.microsoft.com/user.read")}
      &state=microsoft${encodeURI(",")}"${this.props.force_email ? encodeURI(this.props.force_email) : ''}${encodeURI(",")}${this.props.join_hash ? encodeURI(this.props.join_hash) : ''}`
  }

  async getUserProfile() {
  try {
    // Get the access token silently
    // If the cache contains a non-expired token, this function
    // will just return the cached token. Otherwise, it will
    // make a request to the Azure OAuth endpoint to get a token

    var accessToken = await this.userAgentApplication.acquireTokenSilent({
        scopes: scopes
      });

    if (accessToken) {
      // TEMPORARY: Display the token in the error flash
      alert(JSON.stringify(accessToken));
      this.setState({
        isAuthenticated: true,
        error: { message: "Access token:", debug: accessToken.accessToken }
      });
    }
  }
  catch(err) {
    console.log(err);
    var errParts = err.split('|');
    this.setState({
      isAuthenticated: false,
      user: {},
      error: { message: errParts[1], debug: errParts[0] }
    });
  }
}

  async login() {
    try {
      await this.userAgentApplication.loginPopup(
          {
            scopes: scopes,
            prompt: "select_account"
        });
      await this.getUserProfile();
    }
    catch(err) {
      var error = {};

      if (typeof(err) === 'string') {
        var errParts = err.split('|');
        error = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err)
        };
      }

      this.setState({
        isAuthenticated: false,
        user: {},
        error: error
      });
    }
  }

  responseGoogle(response) {
    if (response.code) {
      this.props.registerUserWithGoogle(
        response.code,
        this.props.email_check ? this.props.email_check.toLowerCase() : null,
        this.props.join_hash,
        this.props.is_from_invite
      );
    } else {
      console.log("error", response);
    }
  }

  renderButton() {
    return (
      <button className="Login-ms" style={{ background: "transparent", border: "none", marginTop: 15, padding: "0" }} onClick={() => this.testReq()}>
        <img style={{ cursor: "pointer" }} width="200" src={msSignup} alt="Sign in with Microsoft" />
      </button>
    );
  }

  render() {
    if (this.props.inlineButton) {
      return this.renderButton();
    } else {
      return this.renderButton();
    }
  }
}

const mapStatesToProps = (state) => {
  const { force_email } = state.account;
  return { force_email };
};


export default connect(mapStatesToProps, {
  registerUserWithGoogle,
  setLoginError,
  postRequest
})(MicrosoftButton);
