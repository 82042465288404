import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Icon, Spin } from 'antd';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import PageWrapper from '../../common/PageWrapper';
import MeeterHome from './MeeterHome';
import InviteBanner from '../company/InviteBanner';
import Media from "react-media";
import NewRoundBanner from './NewRoundBanner';
import {
  setUserAndCompany
} from '../../actions';
import "./Rounds.css";

const history = createBrowserHistory();
const LIGHT_GRAY = "#989898";
const pageSize = 20;

const getMeetingStatus = (status) => {
  if (status === "MEETINGS_SET") {
    return (
      <div style={{ fontWeight: "bold" }}>Meetings Set <Icon type="check" /></div>
    );
  } else if (status === "SETTING_UP") {
    return (
      <div style={{ color: LIGHT_GRAY }}>Scheduling Meetings <Spin style={{ marginTop: "2px", marginLeft: "4px" }} size="small" /></div>
    );
  }
};

const onRowPressed = (round) => {
  if (round.status !== "SETTING_UP") {
    history.push(`/view_round/${round.key}`);
    window.location.reload();
  }
};

class Round extends Component {

  constructor(props) {
    super(props);
    this.roundCheckInterval = null;
    this.state = {};
    let qString = queryString.parse(window.location.search);
    if (qString.state === "finishingMsft") {
      this.state["finishingMsft"] = true;
      this.state["msftCode"] = qString.code;
    }
  }

  checkRoundStatus(round_id) {
    console.log("checking round status with id", round_id);
    axios.get("/check_round_status", {
      params: {
        user_id: this.props.user.id,
        round_id: round_id
      }
    })
    .then(response => response.data)
    .then(json => {
      if (json.success) {
        clearInterval(this.roundCheckInterval);
        this.roundCheckInterval = null;
        this.props.setUserAndCompany(json.user);
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  getColumns(isLargeScreen) {
    let columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: isLargeScreen ? 200 : 120,
        render(text, record) {
          return {
          	props: {
              style: { color: "#5280e9" },
            },
          	children: record.status === "SETTING_UP" ? <div style={{ color: LIGHT_GRAY }}>{text}</div> : <div style={{ paddingLeft: "0px", color: "#222", fontWeight: 600 }} type="link">{text}</div>,
          };
        }
      },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        key: 'start_date',
        width: 100,
        render(text, record) {
          return {
          	props: {
              style: { background: record.color },
            },
          	children: record.status === "SETTING_UP" ? <div style={{ color: LIGHT_GRAY, fontFamily: "IBM Plex Mono" }}>{text}</div> : <div style={{ fontFamily: "IBM Plex Mono" }}>{text}</div>,
          };
        }
      },
      {
        title: 'End Date',
        dataIndex: 'end_date',
        key: 'end_date',
        width: 100,
        render(text, record) {
          return {
          	props: {
              style: { background: record.color },
            },
          	children: record.status === "SETTING_UP" ? <div style={{ color: LIGHT_GRAY, fontFamily: "IBM Plex Mono" }}>{text}</div> : <div style={{ fontFamily: "IBM Plex Mono" }}>{text}</div>,
          };
        }
      }
    ];

    if (isLargeScreen) {
      columns.push(
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          width: 120,
          render(text, record) {
            return {
            	props: {
                style: { background: record.color },
              },
            	children: <div>{getMeetingStatus(text)}</div>,
            };
          }
        }
      );
    }

    return columns;
  }

  renderRound(round) {
    console.log(round);
    return (
      <div key={round.id} style={{ paddingBottom: "12px" }}>
        <span>
          {round.meeting_title} ({round.start_date} - {round.end_date})
        </span>
      </div>
    );
  }

  renderRounds(rounds) {
    let data_array = [];

    rounds.forEach((r) => {
      const r_obj = {
        key: r.id,
        title: r.meeting_title,
        start_date: r.start_date,
        end_date: r.end_date,
        status: r.status
      };
      data_array.push(r_obj)
      if (r.status === "SETTING_UP") {
        if (this.roundCheckInterval == null) {
          this.roundCheckInterval = setInterval(()=> this.checkRoundStatus(r.id), 10000);
          console.log("setting up interval", this.roundCheckInterval);
        }
      }
    });

    return (
      <Media query="(min-width: 599px)">
        {matches => {
          return (
            <Table
              pagination={data_array.length > pageSize && { pageSize }}
              columns={this.getColumns(matches)}
              dataSource={data_array}
              style={{ maxWidth: "1020px", cursor: "pointer" }}
              onRow={(record) => ({
                onClick: () => onRowPressed(record)
              })}
            />
          );
        }}
      </Media>
    );
  }

  renderUpcomingRoundContainer() {
    if (this.props.company.upcoming_rounds.length > 0) {
      return (
        <div>
        <h2 className="Rounds-table-header"> Live and Upcoming Rounds </h2>
        {this.renderRounds(this.props.company.upcoming_rounds)}
        </div>
      );
    }
  }

  renderPastRoundContainer() {
    if (this.props.company.past_rounds.length > 0) {
      return (
        <div style={{ marginTop: "40px" }}>
          <h2 className="Rounds-table-header"> Completed Rounds </h2>
          {this.renderRounds(this.props.company.past_rounds)}
        </div>
      );
    }
  }

  onCreateClicked() {
    this.props.history.push({
      pathname: '/create_round'
    });
  }

  renderHeaderInfo() {
    return (
      <div style={{ marginBottom: "40px" }}>
        <div style={{ display: "flex" }}>
          <h1 style={{ fontFamily: "IBM Plex Mono", fontWeight: 600, flexGrow: 1 }}>Meeting Rounds</h1>
        </div>
        <NewRoundBanner history={this.props.history} msftCode={this.state.msftCode} finishingMsft={this.state.finishingMsft} hasRounds={this.props.company.cal_manager_id} />
      </div>
    );
  }

  renderContent() {
    if (this.props.user.role === "MEETER") {
      return <MeeterHome user={this.props.user} />;
    } else {
      return (
        <div>
          <PageWrapper>
            <InviteBanner join_url={this.props.company.join_url} />
            <div style={{ marginTop: "30px" }}>
              {this.renderHeaderInfo()}
              {this.renderUpcomingRoundContainer()}
              {this.renderPastRoundContainer()}
            </div>
          </PageWrapper>
        </div>
      );
    }
  }

  render() {
    console.log("rounds", this.props.company.rounds);
    return (
      this.renderContent()
    );
  }
}

const mapStatesToProps = (state) => {
  const { user, name, logged_in } = state.account;
  const { company } = state.company;
  return { user, name, logged_in, company };
};

export default connect(mapStatesToProps, {
  setUserAndCompany
})(Round);
