import {
  postAPIRequest
} from '../global_vars';
import {
  SET_DEPARTMENTS,
  SET_INVITE_COMPANY_NAME,
  SET_COMPANY
} from './types';
import { setLoginError, setUserAndCompany } from './AccountActions';

export const createCompany = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(postAPIRequest('/create_company', {...data}, (json) => {
      if (json.success) {
        onSuccess(json);
      } else {
        dispatch(setLoginError(json));
      }
    }));
  };
};

export const setNumMembers = (count) => {
  return (dispatch, getState) => {
    const currState = getState();
    const company = currState.company.company;
    company.num_members = count;
    dispatch({ type: SET_COMPANY, payload: { ...company } })
  }
}

export const setCalManagerId = (id) => {
  return (dispatch, getState) => {
    const currState = getState();
    const company = currState.company.company;
    company.cal_manager_id = id;
    dispatch({ type: SET_COMPANY, payload: { ...company } })
  }
}

export const inviteUser = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(postAPIRequest('/invite_user', {...data}, (json) => {
      if (json.success) {
        dispatch(setUserAndCompany(json.user));
        onSuccess();
      } else {
        console.log("error invite json", json);
        dispatch(setLoginError(json));
      }
    }));
  };
};

export const removeCollaborator = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(postAPIRequest('/remove_collaborator', {...data}, (json) => {
      console.log(json);
      if (json.success) {
        dispatch(setUserAndCompany(json.user));
        onSuccess();
      } else {
        console.log("error invite json", json);
        dispatch(setLoginError(json));
      }
    }));
  };
};

export const resendInvite = (user_id, company_id) => {
  return (dispatch) => {
    dispatch(postAPIRequest('/resend_invite', { user_id: user_id, company_id: company_id }, (json) => {
      console.log(json);
      if (json.success) {
        console.log(json);
      }
    }));
  };
};

export const submitPaymentInfo = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(postAPIRequest('/submit_payment_info', { ...data }, (json) => {
      onSuccess(json);
    }));
  };
};

export const getCompanyNameFromJoinHash = (data) => {
  return (dispatch) => {
    dispatch(postAPIRequest('/get_company_name_from_join_hash', { ...data }, (json) => {
      if (json.success) {
        dispatch({ type: SET_INVITE_COMPANY_NAME, payload: json.name })
      }
    }));
  };
}

export const cancelSubscription = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(postAPIRequest('/cancel_subscription', { ...data }, (json) => {
      onSuccess(json);
    }));
  };
};

export const setDepartments = (departments) => {
  return (dispatch) => {
    dispatch({ type: SET_DEPARTMENTS, payload: departments });
  };
};
