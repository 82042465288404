import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import DisableButton from '../../common/DisableButton';
import {
  logUserOut
} from '../../actions';

class ShowVerify extends Component {

  state = { has_sent_resend: false, is_loading: false };

  handleResend() {
    this.setState({ is_loading: true });
    axios.post("/resend_verify", { user_id: this.props.user.id })
    .then(response => response.data)
    .then(json => {
      if (json.success) {
        this.setState({
          has_sent_resend: true
        });
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  renderResendButton() {
    if (this.state.has_sent_resend) {
      return <div>An email to verify your account has been sent to {this.props.user.email}. Please allow a couple of minutes to receive </div>;
    } else {
      return (
        <DisableButton
          label="Resend Verification Link"
          onClick={() => this.handleResend()}
          show_spinner={this.state.is_loading}
        />
      );
    }
  }

  onLogout() {
    this.props.logUserOut(() => {
      const history = createBrowserHistory();
      history.push('/');
      window.location.reload();
    });
  }

  render() {
    return (
      <div style={styles.containerStyle}>
        <div style={styles.displayContainer}>
          <h2>Verify Your Email Address</h2>

          <div style={{ paddingBottom: "20px" }}>
            Verify your email address to begin using Parade!
          </div>

          {this.renderResendButton()}

          <button style={styles.logoutContainer} onClick={() => this.onLogout()}>
            Logout
          </button>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  logUserOut
})(ShowVerify);

const styles = {
  containerStyle: {
    position: 'fixed',
    display: 'flex',
    left: 0, right: 0, top: 0, bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px'
  },
  displayContainer: {
    backgroundColor: '#fff',
    maxWidth: '600px',
    width: '100%',
    borderRadius: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: "50px 0px",
    position: 'absolute'
  },
  logoutContainer: {
    position: 'absolute',
    top: "12px",
    right: '12px',
    backgroundColor: 'rgba(0,0,0,0)',
    borderWidth: "0px",
    color: 'red',
    fontSize: "12px",
    cursor: "pointer"
  }

}
