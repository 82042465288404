import React, { Component } from 'react';
import { connect } from 'react-redux';
import onClickOutside from "react-onclickoutside";
import { createBrowserHistory } from 'history';
import { Link } from 'react-router-dom';
import xImage from '../../common/x.png';
import {
  setAccountMenu,
  logUserOut
} from '../../actions';

const history = createBrowserHistory();

class AccountMenu extends Component {
  handleClickOutside = evt => {
    this.props.setAccountMenu(false);
  };

  onAccountMenuPressed() {
    this.props.setAccountMenu(!this.props.show_account_menu);
  }

  onLogout() {
    this.props.logUserOut(() => {
      history.push('/');
      window.location.reload();
    });
  }

  onLinkClicked(path) {
    history.push(path);
  }

  renderLinkWithNameAndPath(name, path) {
    return <Link to={path} onClick={() => this.props.setAccountMenu(false)} style={styles.linkStyle}>{name}</Link>;
  }

  renderCompany() {
    if (this.props.user.is_admin) {
      return this.renderLinkWithNameAndPath("Company Settings", "/company_settings");
    }
  }

  renderPayment() {
    if (this.props.user.is_admin) {
      return null; //this.renderLinkWithNameAndPath("Payment Settings", "/payment_settings")
    }
  }

  renderAccountMenu() {
    if (this.props.show_account_menu) {
      return (
        <div style={styles.containerStyle}>
          <div style={styles.nameContainer}>
            {this.props.user.first_name} {this.props.user.last_name}
            <img style={styles.xImageStyle} src={xImage} alt="logo" onClick={() => this.props.setAccountMenu(false)} />
          </div>
          <div style={styles.sepStyle} />
          <div style={{ marginTop: '4px' }} />
          {this.renderLinkWithNameAndPath("Account Settings", "/account_settings")}
          {this.renderCompany()}
          {this.renderPayment()}
          <div style={{ marginTop: '4px' }} />
          <div style={styles.sepStyle} />
          <a style={styles.linkStyle} href="mailto:hello@meetloaf.co">
            Help (hello@meetloaf.co)
          </a>
          <div style={{ marginTop: '4px' }} />
          <div style={styles.sepStyle} />
          <div style={styles.linkStyle} onClick={() => this.onLogout()}>
            Log Out
          </div>
        </div>
      );
    }
  }


  render() {
    return (
      <div style={{ zIndex: 2, position: 'absolute', right: "12px", top: "12px", display: "flex", alignItems: "center" }}>
        {this.props.company ? <div className='mono-font' style={{ fontSize: 14, fontWeight: 700, maxWidth: 120, overflow: 'hidden', whiteSpace: 'nowrap' }}>{this.props.company.name}</div> : null}
        <button style={styles.accountStyle} onClick={() => this.onAccountMenuPressed()}>
          {this.props.user.initials}
        </button>
        {this.renderAccountMenu()}
      </div>
    )
  }
}

const mapStatesToProps = (state) => {
  const { user, show_account_menu } = state.account;
  const { company } = state.company;
  return { user, show_account_menu, company };
};

export default connect(mapStatesToProps, {
  setAccountMenu,
  logUserOut
})(onClickOutside(AccountMenu));

const styles = {
  containerStyle: {
    width: "260px",
    paddingBottom: "18px",
    backgroundColor: '#fff',
    position: 'absolute',
    right: "8px",
    top: "48px",
    borderRadius: "3px",
    borderWidth: "1px",
    borderStyle: 'solid',
    borderColor: '#D3D3D3',
    boxShadow: "0px 10px 10px 0px #D3D3D3",
    display: 'flex',
    flexDirection: 'column',
    zIndex: 2
  },
  nameContainer: {
    textAlign: 'center',
    paddingTop: "12px",
    fontSize: "12px",
    color: '#484848',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  sepStyle: {
    marginLeft: '12px',
    marginRight: '12px',
    backgroundColor: '#E0E0E0',
    height: '1px',
    marginTop: '12px'
  },
  xImageStyle: {
    height: '10px',
    width: '10px',
    position: 'absolute',
    right: '20px',
    cursor: 'pointer'
  },
  linkStyle: {
    textDecoration: 'none',
    color: 'black',
    fontSize: '14px',
    marginLeft: '12px',
    marginTop: '16px',
    cursor: 'pointer'
  },
  accountStyle: {
    marginLeft: "12px",
    width: "30px",
    height: "30px",
    borderRadius: "15px",
    backgroundColor: "#ff0080",
    color: "white",
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    fontSize: '11px',
    fontWeight: 'bold',
    cursor: 'pointer',
    border: 'none',
    outline: 'none'
  }
}
