import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements';
import {
  submitPaymentInfo,
  setUserAndCompany
} from '../../../actions';
import './stripe-react.css';

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

class StyledCheckout extends Component {
  state = {
    errorMessage: '',
    zip_code: ""
  };

  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  handleZipChange(val) {
    if (val.length > 5) return;
    let final_val = val.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    this.setState({ zip_code: final_val });
  }

  checkForm() {

  }

  handleSubmit() {
    if (this.state.zip_code.length < 5) {
      this.setState({ errorMessage: "Please enter all 5 digits of your zip code" });
      return;
    }

    this.setState({ errorMessage: null });

    if (this.props.stripe) {
      this.props.stripe.createToken().then((result) => {
        if (result.token) {
          const { token } = result;
          let { last4, exp_month, exp_year, brand } = token.card;
          this.props.submitPaymentInfo({
            token_id: token.id,
            last4,
            exp_month,
            exp_year,
            brand,
            company_id: this.props.company.id,
            user_id: this.props.user.id,
            is_subscribing: true
          }, (json) => {
            console.log("response on submit payment", json);
            if (json.success) {
              this.props.setUserAndCompany(json.user);
              this.props.onSuccess();
            } else {
              this.setState({ errorMessage: json.reason });
            }
          });
        }
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    return (
      <div id="stripe">
        <div className="split-form">
          <label>
            Card number
            <CardNumberElement
              {...createOptions()}
              onChange={this.handleChange}
            />
          </label>
          <label>
            Expiration date
            <CardExpiryElement
              {...createOptions()}
              onChange={this.handleChange}
            />
          </label>
        </div>
        <div className="split-form">
          <label>
            CVC
            <CardCVCElement {...createOptions()} onChange={this.handleChange} />
          </label>
          <label>
            Postal code
            <input
              name="name"
              type="text"
              placeholder="94115"
              className="StripeElement"
              required
              value={this.state.zip_code}
              onChange={(e) => this.handleZipChange(e.target.value)}
            />
          </label>
        </div>
        <button onClick={() => this.handleSubmit()}>subscribe</button>
        <div className="error" role="alert">
          {this.state.errorMessage}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user, login_error } = state.account;
  const { company } = state.company;
  return { user, company, login_error };
}

export default connect(mapStatesToProps, {
  submitPaymentInfo,
  setUserAndCompany
})(injectStripe(StyledCheckout));
