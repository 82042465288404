import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Login from '../Login';
import Register from '../Register';
import PasswordReset from '../PasswordReset';
import ForgotPassword from '../ForgotPassword';
import VerifyEmail from '../../common/VerifyEmail';
import PrivacyPolicy from '../../common/PrivacyPolicy';
import Terms from '../../common/Terms';
import NavBar from './navbar';

class RouterComponent extends Component {
  renderNavBar() {
    if (!window.location.pathname.includes("ver_em")) {
      return <NavBar />;
    }
  }

  render() {
    return (
      <Router history={this.props.history}>
        <Switch>
          <Route path='/' exact component={Login} />
          <Route path='/login/:invite_hash' component={Login} />
          <Route path='/get_started' component={Login} />
          <Route path='/join/:join_hash' component={Login} />
          <Route path='/login' component={Login} />
          <Route path='/register/:invite_hash' component={Login} />
          <Route path='/register/' component={Login} />
          <Route path='/privacy' component={PrivacyPolicy} />
          <Route path='/terms' component={Terms} />
          <Route path='/forgot_password' component={ForgotPassword} />
          <Route path="/ver_em/:token" component={VerifyEmail}/>
          <Route path="/ps_res/:token" component={PasswordReset}/>
          <Route path="/up_em/:token" component={Login}/>
          <Route path='/join/:join_hash' component={Login} />
          <Route component={Login} />
        </Switch>
      </Router>
    );
  }
}

export default RouterComponent;
