import React, { Component } from 'react';

class PopUpForm extends Component {
  renderCancel() {
    if (!this.props.hideCancel) {
      return (
        <button style={styles.logoutContainer} onClick={() => this.props.onCancel()}>
          CANCEL
        </button>
      );
    }
  }

  render() {
    return (
      <div style={styles.containerStyle} onClick={() => this.props.onCancel()}>
        <div style={{...styles.displayContainer,
                     ...{ backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : "#fff" },
                     ...{ minHeight: this.props.minHeight ? this.props.minHeight : "0px" },
                     ...{ alignItems: this.props.alignItems ? this.props.alignItems : "center" },
                     ...{ justifyContent: this.props.justifyContent ? this.props.justifyContent : "center" }
                   }}
            onClick={(e) => e.stopPropagation()}
        >
          {this.props.children}
          {this.renderCancel()}
        </div>
      </div>
    );
  }
}

export default PopUpForm;

const styles = {
  containerStyle: {
    position: 'fixed',
    display: 'flex',
    left: 0, right: 0, top: 0, bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    zIndex: 1
  },
  displayContainer: {
    backgroundColor: '#fff',
    maxWidth: '800px',
    height: "100%",
    maxHeight: "600px",
    width: '100%',
    borderRadius: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: "50px 30px",
    boxSizing: "border-box",
    position: "relative"
  },
  logoutContainer: {
    position: 'absolute',
    top: "12px",
    right: '12px',
    backgroundColor: 'rgba(0,0,0,0)',
    borderWidth: "0px",
    color: 'gray',
    fontSize: "12px",
    cursor: "pointer"
  }
}
