import React, { Component } from 'react';
import { Button, Tag } from 'antd';
import moment from 'moment';
import SetUpBusinessHours from './SetUpBusinessHours';

class BusinessSettings extends Component {
  state = { show_business_hours: false };

  renderBusinessHours() {
    console.log(this.props.user);
    if (this.state.show_business_hours) {
      return (
        <SetUpBusinessHours
          user={this.props.user}
          closable
          visible={this.state.show_business_hours}
          onCancel={() => this.setState({ show_business_hours: false })}
          selected_days={this.props.user.available_days}
          is_edit
          start_time={this.props.user.day_start}
          end_time={this.props.user.day_end}
          joinDate={this.props.user.joinDate}
          birthdate={this.props.user.birthdate}
          departmentId={this.props.user.departmentId}
          time_zone={this.props.user.time_zone}
        />
      );
    }
  }

  renderDays() {
    return this.props.user.days_array.map((d) => <Tag key={d}>{d}</Tag>)
  }

  render() {
    return (
      <div>
        <div className="BusinessSettings-main-header"> Business Hours </div>
        <div className="BusinessSettings-sub-header">
          The typical days and hours that you're available to meet. We'll only schedule meetings within these timeframes that don't conflict with your calendar.
        </div>
        <div className="BusinessSettings-info-container">
          <div className="BusinessSettings-content-header">
            Days of the week
          </div>
          <div className="BusinessSettings-content-description" style={{ marginBottom: "20px" }}>
            {this.renderDays()}
          </div>

          <div className="BusinessSettings-content-header">
            Start work
          </div>
          <div className="BusinessSettings-content-description">
            {this.props.user.day_start}
          </div>

          <div className="BusinessSettings-content-header">
            Finish work
          </div>
          <div className="BusinessSettings-content-description">
            {this.props.user.day_end}
          </div>
          <div className="BusinessSettings-content-header">
            Time Zone
          </div>
          <div className="BusinessSettings-content-description">
            {
              this.props.user.time_zone ?
              <span>(GMT {moment.tz(this.props.user.time_zone).format('Z')}) {this.props.user.time_zone}</span> :
              null
            }
          </div>
        </div>

        <Button style={{ width: "100%", maxWidth: "300px" }} type="secondary" onClick={() => this.setState({ show_business_hours: true })}>
          Edit
        </Button>
        {this.renderBusinessHours()}
      </div>
    );
  }
}

export default BusinessSettings;
