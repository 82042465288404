import React, { Component } from 'react';
import { Button, Table, Tag } from 'antd';
import { connect } from 'react-redux';

const pageSize = 30;

class DepartmentTable extends Component {

  constructor(props) {
    super(props);
    this.handleRemove = u => {
      props.showRemoveConfirm({ id: u.key, company_id: props.company.id, name: u.name });
    }
  }

  renderAddButton() {
    return (
      <Button style={styles.addButtonStyle} type="primary" onClick={() => this.props.onEditDepartment()}>
        + Department
      </Button>
    )
  }

  onRemoveUser(u) {
    this.props.showRemoveConfirm(u);
  }

  onResendInvite(u) {
    this.props.onResendInvite(u);
  }

  getDepartmentMap() {
    let map = {};
    (this.props.company.departments || []).forEach((item, i) => {
      map[item.id] = item;
    });
    return map;
  }

  getDisallowedDepartments(department, deptMap) {
    if (!department || !department.department_blacklist || department.department_blacklist.length <= 0) {
      return null;
    }
    return (
      <div style={{ fontWeight: 400 }}>
        <span style={{ marginRight: 8 }}>Can't meet with:</span>
        {
          department.department_blacklist.map((item, i) => {
            return <Tag size="small">{(deptMap[item] || {}).name}</Tag>
          })
        }
      </div>
    );
  }

  getColumns(handleEdit) {
    let deptMap = this.getDepartmentMap();
    const columns = [
      {
        title: 'Department',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => {
          return {
          	props: {
              style: { color: "#5280e9" },
            },
          	children:
            <div style={{ color: "#222", fontWeight: 600 }}>
              <b>{text}</b>
              {this.getDisallowedDepartments(record, deptMap)}
            </div>,
          };
        }
      },
      {
        title: '',
        dataIndex: 'buttons',
        key: 'buttons',
        render(text, department) {
          let buttons = (
            <div style={{ textAlign: "right" }}>
              <Button style={styles.removeButton} onClick={() => handleEdit(department)}>
                Edit
              </Button>
            </div>
          );
          return {
          	children: buttons
          };
        }
      }
    ];
    return columns;
  }

  renderDepartments() {
    let data_array = [];

    this.props.company.departments.forEach((r) => {
      const r_obj = {
        key: r.id,
        name: r.name,
        id: r.id,
        department_blacklist: r.department_blacklist
      };
      data_array.push(r_obj)
    });
    return (
      <Table
        columns={this.getColumns(this.props.onEditDepartment)}
        dataSource={data_array}
        pagination={data_array.length > pageSize && { pageSize }}
        style={{ maxWidth: "1020px" }}
      />
    );
  }


  render() {
    return (
      <div style={styles.containerStyle}>
        {this.renderDepartments()}
        {this.renderAddButton()}
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user } = state.account;
  const { company } = state.company;
  return { user, company };
};

export default connect(mapStatesToProps, {

})(DepartmentTable);

const styles = {
  containerStyle: {
    width: "100%",
    paddingTop: '20px'
  },
  headerRow: {
    display: 'flex',
    borderBottom: "1px solid black",
    justifyContent: 'space-between',
    paddingBottom: '8px'
  },
  headerText: {
    fontSize: '12px',
    fontWeight: 'bold'
  },
  normalRow: {
    display: 'flex',
    borderBottom: "1px solid gray",
    justifyContent: 'space-between',
    paddingBottom: '12px',
    paddingTop: '14px'
  },
  normalText: {
    fontSize: '12px'
  },
  grid: {
    display: 'grid',
    width: "100%",
    gridTemplateColumns: 'repeat(3, 1fr)',
    borderTop: '1px solid black'
  },
  gridSpan: {
    padding: "12px 4px",
    fontSize: '12px',
    borderBottom: '1px solid #D8D8D8'
  },
  gridSpanCenter: {
    padding: "12px 4px",
    textAlign: 'center',
    fontSize: '12px',
    borderBottom: '1px solid #D8D8D8'
  },
  gridSpanRight: {
    padding: "12px 4px",
    textAlign: 'right',
    fontSize: '12px',
    borderBottom: '1px solid #D8D8D8'
  },
  removeButton: {
    fontSize: "12px",
    height: "26px",
    marginLeft: "12px"
  },
  addButtonStyle: {
    marginTop: "40px"
  }
}
