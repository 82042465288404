import { createBrowserHistory } from 'history';
import {
  SET_COMPANY_NAME,
  UPDATE_SESSION,
  SET_USER,
  SET_LOGIN_ERROR,
  SET_COMPANY,
  SET_FORCE_EMAIL,
  SET_IS_INVITE,
  SET_ACCOUNT_MENU,
  SET_INVITE_COMPANY_NAME
} from './types';
import {
  getAPIRequest,
  postAPIRequest
} from '../global_vars';

const history = createBrowserHistory();


export const setUserAndCompany = (user) => {
  return (dispatch) => {
    dispatch({ type: SET_USER, payload: user });
    if (user.has_joined_company) dispatch({ type: SET_COMPANY, payload: user.company });
  };
};

export const checkAuthorized = () => {
  return (dispatch) => {
    dispatch(getAPIRequest('/check_auth', {}, (json) => {
      if (json.success) {
        console.log(json);
        dispatch(setUserAndCompany(json.user));
        dispatch({ type: UPDATE_SESSION, payload: true });
      }
    }));
  };
};

export const setToHomePage = (json, is_from_invite=false) => {
  console.log("setting to home");
  return (dispatch) => {
    dispatch({ type: UPDATE_SESSION, payload: true });
    //if (is_from_invite) history.push('/___'); // jank way to force react router dom to reload route w out refreshing entire page...
    history.push('/');
    // if (is_from_invite) {
    //   window.location.reload();
    // }
  };
}

export const setLoginError = (json) => {
  console.log("SETTING LOGIN ERROR", json);
  return (dispatch) => {
    if (!json) {
      dispatch({ type: SET_LOGIN_ERROR, payload: null });
    } else {
      let reason = "Something went wrong :( Please try again."
      if (json.reason !== null) reason = json.reason;
      dispatch({ type: SET_LOGIN_ERROR, payload: reason });
    }
  };
}

export const registerUser = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_LOGIN_ERROR, payload: null });
    dispatch(postAPIRequest('/register', {...data}, (json) => {
      if (json.success) {
        dispatch(setUserAndCompany(json.user));
        dispatch(setToHomePage(json));
      } else {
        dispatch(setLoginError(json));
      }
    }));
  };
};

export const registerUserWithGoogle = (code, email_check=null, join_hash=null, is_from_invite=false) => {
  return (dispatch) => {
    dispatch({ type: SET_LOGIN_ERROR, payload: null });
    dispatch(postAPIRequest('/register_google', {
      code: code,
      email_check: email_check,
      join_hash: join_hash
    }, (json) => {
      // console.log(json);
      if (json.success) {
        dispatch(setUserAndCompany(json.user));
        dispatch(setToHomePage(json));
      } else {
        if (json.email_check_failed) {
          //onFailure();
          dispatch(setLoginError(json));
        } else {
          dispatch(setLoginError(json));
        }
      }
    }));
  };
};

export const grantCalAccess = (code, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(postAPIRequest('/add_calendar_permission', {
      code: code
    }, (json) => {
      if (json.success && onSuccess) {
        onSuccess(json);
      } else {
        onFail(json.message);
      }
    }));
  }
}

export const grantCalReadAccess = (code, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(postAPIRequest('/add_calendar_read_permission', {
      code: code
    }, (json) => {
      if (json.success) {
        dispatch(setUserAndCompany(json.user));
        dispatch(setToHomePage(json, true));
      } else {
        onFail(json.message);
      }
    }));
  }
}

export const registerUserWithMicrosoft = (code, email_check=null, join_hash=null, is_from_invite=false) => {
  return (dispatch) => {
    dispatch({ type: SET_LOGIN_ERROR, payload: null });
    dispatch(postAPIRequest('/register_microsoft', {
      code: code,
      email_check: email_check,
      join_hash: join_hash
    }, (json) => {
      // console.log(json);
      if (json.success) {
        dispatch(setUserAndCompany(json.user));
        dispatch(setToHomePage(json));
      } else {
        if (json.email_check_failed) {
          //onFailure();
          dispatch(setLoginError(json));
        } else {
          dispatch(setLoginError(json));
        }
      }
    }));
  };
};

export const loginUser = (data, isEmailUpdate=false, update_path=null) => {
  return (dispatch) => {
    dispatch({ type: SET_LOGIN_ERROR, payload: null });
    dispatch(postAPIRequest('/login', {...data}, (json) => {
      if (json.success) {
        if (!isEmailUpdate) {
          dispatch(setUserAndCompany(json.user));
          dispatch(setToHomePage(json));
        } else {
          dispatch(setUserAndCompany(json.user));
          dispatch({ type: UPDATE_SESSION, payload: true });
          history.push(update_path);
        }
      } else {
        dispatch(setLoginError(json));
      }
    }));
  };
};

export const logUserOut = (onSuccess) => {
  return (dispatch) => {
    dispatch(postAPIRequest('/logout', {}, (json) => {
      if (json.success) {
        onSuccess();
        dispatch({ type: UPDATE_SESSION, payload: false });
      }
    }));
  };
};


export const setCompanyName = (name) => {
  return (dispatch) => {
    dispatch({ type: SET_COMPANY_NAME, payload: name });
  };
};

export const setLoggedIn = (isLoggedIn) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SESSION, payload: isLoggedIn });
  };
};

export const setForcedEmail = (email) => {
  return (dispatch) => {
    dispatch({ type: SET_FORCE_EMAIL, payload: email });
  };
};

export const setIsInvite = (is_invite, invite_hash=null) => {
  return (dispatch) => {
    dispatch({ type: SET_IS_INVITE, payload: is_invite });
    if (invite_hash) dispatch(getEmailFromHash(invite_hash));
  };
};

export const getEmailFromHash = (invite_hash) => {
  return (dispatch) => {
    dispatch(postAPIRequest('/get_email_from_hash', { invite_hash: invite_hash }, (json) => {
      console.log(json);
      if (json.success) {
        dispatch({ type: SET_FORCE_EMAIL, payload: json.email });
        dispatch({ type: SET_INVITE_COMPANY_NAME, payload: json.company_name });
      }
    }));
  };
};


export const resetPassword = (password, onSuccess) => {
  return (dispatch) => {
    dispatch(postAPIRequest('/res_pwd', {}, (json) => {
      if (json.success) {
        onSuccess();
      }
    }));
  };
};

export const setAccountMenu = (showMenu) => {
  return (dispatch) => {
    dispatch({ type: SET_ACCOUNT_MENU, payload: showMenu });
  };
};

export const updateProfile = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(postAPIRequest('/update_profile', {...data}, (json) => {
      if (json.success) {
        dispatch(setUserAndCompany(json.user));
        onSuccess();
      }
    }));
  };
}

export const setBusinessHours = (data, is_edit=false, onSuccess) => {
  return (dispatch) => {
    dispatch(postAPIRequest('/set_business_hours', {is_edit: is_edit, ...data}, (json) => {
      if (json.success) {
        dispatch(setUserAndCompany(json.user));
        if (is_edit) {
          onSuccess();
        } else {
          dispatch(setToHomePage(json, true));
        }
      }
    }));
  };
}
