import React, { Component } from 'react';
import { Button } from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';
import LocationSearchInput from './LocationSearchInput';
import {
  setUserAndCompany
} from '../../actions';

class AddLocations extends Component {

  state = { location: "", is_loading: false }

  handleSubmit() {
    this.setState({ is_loading: true });
    axios.post("/create_location", {
      company_id: this.props.company.id,
      user_id: this.props.user.id,
      name: this.state.location
    })
    .then(response => response.data)
    .then(json => {
      console.log("create location response", json);
      if (json.success) {
        this.props.setUserAndCompany(json.user);
        this.props.onComplete(json.location_id);
        // this.props.history.push({
        //   pathname: '/rounds'
        // });
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  shouldBeEnabled() {
    return this.state.location.length > 0;
  }

  renderNewLocationForm() {
    let button = null;
    if (this.state.location !== "") {
      button =    <Button
                    type="primary"
                    style={{ height: "32px", marginLeft: "20px" }}
                    onClick={() => this.handleSubmit()}
                    disabled={!this.shouldBeEnabled()}
                    loading={this.state.is_loading}
                  >
                    + Location
                  </Button>
    }

    return (
      <span>
        <h4> Enter Location </h4>
        <span style={{ display: "flex", flexDirection: "row" }}>
          <LocationSearchInput
            handleChange={(location) => this.setState({ location })}
          />
          {button}
        </span>
      </span>
    );
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        {this.renderNewLocationForm()}
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user, name, logged_in } = state.account;
  const { company } = state.company;
  return { user, name, logged_in, company };
};

export default connect(mapStatesToProps, {
  setUserAndCompany
})(AddLocations);
