import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import BusinessHours from './BusinessHours';
import './JoinCompany.css';
import {
  setBusinessHours
} from '../../actions';

class SetUpBusinessHours extends Component {
  constructor(props) {
    super(props);

    let selected_days = ["Mon", "Tue", "Wed", "Thu", "Fri"];
    if (props.user.has_set_business_hours) {
      selected_days = props.selected_days.replace(/\s/g, '').split(",")
    }

    this.state = {
      selected_days: selected_days,
      start_time: props.start_time || "",
      end_time: props.end_time || "",
      departmentId: props.departmentId,
      joinDate: props.joinDate,
      birthdate: props.birthdate,
      time_zone: props.time_zone || moment.tz.guess(),
      visible: true
    };
  }

  checkDepartments() {
    if (this.props.company.departments.length > 0) {
      return this.state.departmentId != null;
    } else {
      return true;
    }
  }

  shouldBusinessEnable() {
    return this.state.selected_days.length > 0 && this.state.start_time !== "" && this.state.end_time !== "" && this.checkDepartments();
  }

  onSubmit() {
    this.setState({ settingBusinessHours: true });
    let params = {

    }
    this.props.setBusinessHours({
      available_days: this.state.selected_days,
      start_time: this.state.start_time,
      end_time: this.state.end_time,
      user_id: this.props.user.id,
      department_id: this.state.departmentId,
      join_date: this.state.joinDate,
      birthdate: this.state.birthdate,
      time_zone: this.state.time_zone
    }, this.props.is_edit, () => {
      this.setState({ settingBusinessHours: false })
      this.props.onCancel()
    });
  }

  render() {
    return (
      <Modal
        title={this.props.is_edit ? "Edit Schedule" : "Welcome to MeetLoaf!"}
        visible={this.props.visible}
        footer={null}
        width="770px"
        closable={this.props.closable}
        bodyStyle={{ minHeight: "400px", paddingBottom: 80 }}
        onCancel={() => this.props.closable && this.props.onCancel()}
      >
        <BusinessHours
          is_edit={this.props.is_edit}
          explainer={this.props.user.is_admin ? "Enter your information and availability for meetings so when you create your first meeting rounds you can participate!" : "Enter your information and availability for meetings so we can pair you!"}
          onDaysChanged={(v) => this.setState({ selected_days: v})}
          onStartTimeChanged={(v) => this.setState({ start_time: v})}
          onLeaveTimeChanged={(v) => this.setState({ end_time: v})}
          onDepartmentChanged={(v) => this.setState({ departmentId: v})}
          onJoinDateChanged={(v) => this.setState({ joinDate: v})}
          onBirthdateChanged={(v) => this.setState({ birthdate: v})}
          onTimeZoneChanged={(v) => this.setState({ time_zone: v})}
          selected_days={this.state.selected_days}
          start_time={this.state.start_time}
          end_time={this.state.end_time}
          departmentId={this.state.departmentId}
          joinDate={this.state.joinDate}
          birthdate={this.state.birthdate}
          time_zone={this.state.time_zone}
        />
        <div className="JoinCompany-steps-footer-container">
          <Button loading={this.state.settingBusinessHours} type="primary" disabled={!this.shouldBusinessEnable()} onClick={() => this.onSubmit()}>
            Submit
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStatesToProps = (state) => {
  const { company } = state.company;
  const { user } = state.account;
  return { company, user };
};


export default connect(mapStatesToProps, {
  setBusinessHours
})(SetUpBusinessHours);
