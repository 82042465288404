export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const UPDATE_SESSION = "UPDATE_SESSION";
export const SET_USER = "SET_USER";
export const SET_LOGIN_ERROR = "SET_LOGGED_IN";
export const SET_COMPANY = "SET_COMPANY";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const SET_FORCE_EMAIL = "SET_FORCE_EMAIL";
export const SET_IS_INVITE = "SET_IS_INVITE";
export const SET_ACCOUNT_MENU = "SET_ACCOUNT_MENU";
export const SET_ROUNDS = "SET_ROUNDS";
export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const SET_INVITE_COMPANY_NAME = "SET_INVITE_COMPANY_NAME";
