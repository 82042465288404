import moment from 'moment';
const USER = function (data) {
  this.id = data.id;
  this.first_name = data.first_name;
  this.last_name = data.last_name;
  this.email = data.email;
  this.has_verified_email = data.has_verified_email;
  this.has_joined_company = data.has_joined_company;
  this.has_invite = data.has_invite;
  this.invite_info = data.invite_info;
  this.is_admin = data.is_admin;
  this.initials = data.initials;
  this.has_password = data.has_password;
  this.has_set_business_hours = data.has_set_business_hours;
  this.available_days = data.available_days;
  this.day_start = data.day_start;
  this.day_end = data.day_end;
  this.role = data.role;
  this.days_array = data.days_array;
  this.departmentId = data.department_id;
  this.departmentName = data.department_name;
  this.joinDate = data.join_date ? moment(data.join_date) : null;
  this.birthdate = data.birthdate ? moment(data.birthdate) : null;
  this.time_zone = data.time_zone;
  this.google = data.google;
  this.current_scope = data.current_scope;
  this.signed_in_with_google = data.signed_in_with_google;
  this.signed_in_with_microsoft = data.signed_in_with_microsoft;
};

export default USER;
