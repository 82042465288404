import React, { Component } from 'react';
import { connect } from 'react-redux';
import CollaboratorTable from './CollaboratorTable';
import DepartmentTable from './DepartmentTable';
import AddCollaborator from './AddCollaborator';
import EditDepartment from './EditDepartment';
import RemoveConfirm from './RemoveConfirm';
import {
  setLoginError,
  resendInvite
} from '../../../actions';

class CompanySettings extends Component {
  state = {
    show_add_collaborator: false,
    show_edit_department: false,
    show_remove_collaborator: false,
    collaborator: null
  }

  renderAddCollaborator() {
    return (
      <AddCollaborator
        onSuccess={() => this.setState({ show_add_collaborator: false })}
        onCancel={() => this.setState({ show_add_collaborator: false })}
        visible={this.state.show_add_collaborator}
      />
    );
  }

  renderEditDepartment() {
    if (this.state.show_edit_department) {
      return (
        <EditDepartment
          visible
          departmentToEdit={this.state.departmentToEdit}
          onSuccess={() => this.setState({ show_edit_department: false, departmentToEdit: null })}
          onCancel={() => this.setState({ show_edit_department: false, departmentToEdit: null })}
        />
      );
    }
  }

  renderRemoveCollaborator() {
    if (this.state.show_remove_collaborator) {
      return (
        <RemoveConfirm
          onSuccess={() => this.setState({ show_remove_collaborator: false })}
          collaborator={this.state.collaborator}
          onCancel={() => this.setState({ show_remove_collaborator: false })}
          visible={this.state.show_remove_collaborator}
        />
      );
    }
  }

  onAddCollaborator() {
    this.props.setLoginError(false);
    this.setState({ show_add_collaborator: true });
  }

  onEditDepartment(department) {
    this.setState({ departmentToEdit: department, show_edit_department: true })
  }

  renderCollaboratorTable() {
    if (this.props.user.is_admin) {
      return (
        <CollaboratorTable
          onAddCollaborator={() => this.onAddCollaborator()}
          showRemoveConfirm={(u) => this.setState({ collaborator: u, show_remove_collaborator: true })}
          onResendInvite={(u) => this.props.resendInvite(u.id, this.props.company.id)}
        />
      );
    }
  }

  renderDepartmentTable() {
    return (
      <DepartmentTable
        onEditDepartment={(department) => this.onEditDepartment(department)}
      />
    );
  }

  render() {
    return (
      <div style={styles.containerStyle}>
        <h1 className="mono-font">Company Settings</h1>
        <div style={styles.headerStyle}> Departments </div>
        <h4> Set your departments </h4>
        {this.renderDepartmentTable()}
        <div style={{ marginBottom: 40 }}></div>
        <div style={styles.headerStyle}> Collaborators </div>
        <h4> You can invite other members of your team to have collaborator priveleges: they can create meeting rounds and invite other members. </h4>
        {this.renderCollaboratorTable()}
        {this.renderAddCollaborator()}
        {this.renderRemoveCollaborator()}
        {this.renderEditDepartment()}
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user } = state.account;
  const { company } = state.company;
  return { user, company };
};

export default connect(mapStatesToProps, {
  setLoginError,
  resendInvite
})(CompanySettings);

const styles = {
  containerStyle: {
  },
  headerStyle: {
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: "IBM Plex Mono",
    color: "#000",
    marginBottom: "8px"
  }
}
