import React, { Component } from 'react';
import { Button, Table } from 'antd';
import { connect } from 'react-redux';

const pageSize = 30;

class CollaboratorTable extends Component {

  constructor(props) {
    super(props);

    this.handleResendInvite = u => {
      props.onResendInvite({ id: u.key, company_id: props.company.id });
    }

    this.handleRemove = u => {
      props.showRemoveConfirm({ id: u.key, company_id: props.company.id, name: u.name });
    }
  }

  renderAddButton() {
    return (
      <Button style={styles.addButtonStyle} type="primary" onClick={() => this.props.onAddCollaborator()}>
        + Collaborator
      </Button>
    )
  }

  onRemoveUser(u) {
    this.props.showRemoveConfirm(u);
  }

  onResendInvite(u) {
    this.props.onResendInvite(u);
  }

  getColumns(handleResendInvite, handleRemove) {
    const columns = [
      {
        title: 'Collaborator',
        dataIndex: 'name',
        key: 'name',
        width: 180,
        render(text, record) {
          return {
          	props: {
              style: { color: "#5280e9" },
            },
          	children: <div style={{ color: "#222", fontWeight: 600 }}><b>{text}</b></div>,
          };
        }
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        width: 150,
        render(text, record) {
          return {
          	props: {
              style: { background: record.color },
            },
          	children: <div>{text}</div>
          };
        }
      },
      {
        title: '',
        dataIndex: 'buttons',
        key: 'buttons',
        width: 100,
        render(text, user) {
          let buttons = null;
          if (user.role !== "admin") {
            let resend_button = null;
            if (user.role === "invite sent") {
              resend_button = (
                <Button style={styles.removeButton} onClick={() => handleResendInvite(user)}>
                  resend invite
                </Button>
              );
            }

            buttons = (
              <div>
                {resend_button}
                <Button style={styles.removeButton} onClick={() => handleRemove(user)}>
                  remove
                </Button>
              </div>
            );
          }
          return {
          	children: buttons
          };
        }
      }
    ];
    return columns;
  }

  renderCollaborators() {
    let data_array = [];

    this.props.company.collaborators.forEach((r) => {
      const r_obj = {
        key: r.id,
        name: r.name,
        role: r.role
      };
      data_array.push(r_obj)
    });
    return (
      <Table
        columns={this.getColumns(this.handleResendInvite, this.handleRemove)}
        dataSource={data_array}
        pagination={data_array.length > pageSize && { pageSize }}
        style={{ maxWidth: "1020px" }}
      />
    );
  }


  render() {
    return (
      <div style={styles.containerStyle}>
        {this.renderCollaborators()}
        {this.renderAddButton()}
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user } = state.account;
  const { company } = state.company;
  return { user, company };
};

export default connect(mapStatesToProps, {

})(CollaboratorTable);

const styles = {
  containerStyle: {
    width: "100%",
    paddingTop: '20px'
  },
  headerRow: {
    display: 'flex',
    borderBottom: "1px solid black",
    justifyContent: 'space-between',
    paddingBottom: '8px'
  },
  headerText: {
    fontSize: '12px',
    fontWeight: 'bold'
  },
  normalRow: {
    display: 'flex',
    borderBottom: "1px solid gray",
    justifyContent: 'space-between',
    paddingBottom: '12px',
    paddingTop: '14px'
  },
  normalText: {
    fontSize: '12px'
  },
  grid: {
    display: 'grid',
    width: "100%",
    gridTemplateColumns: 'repeat(3, 1fr)',
    borderTop: '1px solid black'
  },
  gridSpan: {
    padding: "12px 4px",
    fontSize: '12px',
    borderBottom: '1px solid #D8D8D8'
  },
  gridSpanCenter: {
    padding: "12px 4px",
    textAlign: 'center',
    fontSize: '12px',
    borderBottom: '1px solid #D8D8D8'
  },
  gridSpanRight: {
    padding: "12px 4px",
    textAlign: 'right',
    fontSize: '12px',
    borderBottom: '1px solid #D8D8D8'
  },
  removeButton: {
    fontSize: "12px",
    height: "26px",
    marginLeft: "12px"
  },
  addButtonStyle: {
    marginTop: "40px"
  }
}
