import React, { Component } from 'react';
import axios from 'axios';
import '../App.css';
import DisableButton from '../common/DisableButton';
import { validateEmail } from '../global_vars';

class ForgotPassword extends Component {
  state = {
    sent_request: false,
    email_address: "",
    invalid_email: false,
    invalid_text: "Looks like we can't find that email in our system."
  };

  componentDidMount() {

  }

  handleSubmit() {
    this.setState({ invalid_email: false });
    axios.post("/forgot_password", { email: this.state.email_address })
    .then(response => response.data)
    .then(json => {
      if (json.success) {
        this.setState({
          sent_request: true,
          invalid_email: false
        });
      } else {
        this.setState({
          invalid_email: true,
          sent_request: true
        });
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  shouldBeEnabled() {
    return validateEmail(this.state.email_address);
  }

  renderError() {
    if (this.state.sent_request && this.state.invalid_email) {
      return (
        <div style={{ color: 'red', paddingTop: '20px' }}>
          {this.state.invalid_text}
        </div>
      );
    }
  }

  renderContent() {
    if (this.state.sent_request && !this.state.invalid_email) {
      return (
        <div>
          Help is on the way. We sent an email with instructions. Please allow a minute or two to receive it.
        </div>
      );
    } else {
      return (
        <div className="App" style={{ paddingTop: "120px" }}>
          <label>
            Email Address: <br/>
            <input type="text" value={this.state.email_address} onChange={(e) => this.setState({ email_address: e.target.value })} />
          </label>
          <br/><br/>
          <DisableButton
            disabled={!this.shouldBeEnabled()}
            label="Submit"
            onClick={() => this.handleSubmit()}
          />
          <br/>
          {this.renderError()}
        </div>
      );
    }
  }

  render() {
    return this.renderContent();
  }
}

export default ForgotPassword;
