import React, { Component } from 'react';
import PopUpForm from './PopUpForm';

class ConfirmModal extends Component {
  render() {
    return (
      <PopUpForm onCancel={() => this.props.onCancel()} hideCancel>
        <h4> {this.props.description} </h4>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <button style={styles.buttonStyle} onClick={() => this.props.onConfirm()}>Confirm</button>
          <button onClick={() => this.props.onCancel()}>Cancel</button>
        </div>
      </PopUpForm>
    );
  }
}


export default ConfirmModal;

const styles = {
  buttonStyle: {
    marginRight: "40px"
  }
};
