import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Spin } from 'antd';
import { GoogleLogin } from 'react-google-login';
import '../../App.css';
import {
  logUserOut,
  grantCalAccess,
  setCalManagerId
} from '../../actions';
import { msRedirect } from '../../global_vars';
import '../../common/spinner.css';

class CalendarWrite extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingCal: false,
      successfulSync: false,
      googleLoaded: false
    }
  }

  componentDidMount() {
    if (this.props.finishingMsft) {
      this.createCal(this.props.code);
    }
    if (this.props.user.signed_in_with_google) {
      if (!window.gapi.auth2) {
        window.gapi.load('auth2', () => {
          this.setState({ googleLoaded: true })
        });
      } else {
        this.setState({ googleLoaded: true })
      }
    }
  }

  createCal(code) {
    this.setState({ loadingCal: true });
    this.props.grantCalAccess(code, (resp) => {
      this.props.setCalManagerId(this.props.user.id);
      this.setState({ loadingCal: false, successfulSync: true });
    }, (error) => {
      this.setState({ loadingCal: false, error: error });
    });
  }

  grantMsCalAccess() {
    window.location = `
      https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
      client_id=9bf4f644-14f4-469b-9d3f-09ddfb263f0a
      &response_type=code
      &redirect_uri=${encodeURI(msRedirect)}
      &response_mode=query
      &scope=${encodeURI("openid offline_access profile email https://graph.microsoft.com/user.read https://graph.microsoft.com/calendars.readwrite https://graph.microsoft.com/onlineMeetings.readwrite")}
      &state=finishingMsft
    `
  }

  responseGoogle(response) {
    if (response.code) {
      this.createCal(response.code);
    } else {
      this.setState({ loadingCal: false, error: "An error occured, try again." });
    }
  }

  renderButton() {
    return (
      <>
      {
        this.props.user.signed_in_with_google ?
        <Button
          type="primary"
          size="large"
          disabled={!this.state.googleLoaded}
          onClick={() => {
            window.gapi.auth2.authorize(
              {
                client_id: "535289186539-vor6qfim3rbior7t4v7ro2j71438j08e.apps.googleusercontent.com",
                scope: "email profile https://www.googleapis.com/auth/calendar",
                response_type: "code",
                prompt: "consent",
                login_hint: this.props.user.email
              }
            , (response) => {
              this.responseGoogle(response);
            });
        }}>
          Create MeetLoaf Host Calendar
        </Button> :
        <Button
          type="primary"
          size="large"
          onClick={() => this.grantMsCalAccess()}
        >
          {"Create MeetLoaf Admin Calendar"}
        </Button>
      }
      </>
    );
  }

  render () {
    return (
      <div>
        { !this.state.loadingCal ?
          <>
            { this.state.successfulSync ?
              <>
                <p className="JoinCompany-steps-field-header">
                  Calendar created!
                </p>
                <Button type="primary" size="large" onClick={() => {
                  this.props.history.push({
                    pathname: '/create_round'
                  });
                }}>
                  Create your first round
                </Button>
              </> :
              <>
                { this.state.error ? <p style={{ color: "red" }}>{this.state.error}. Contact hello@meetloaf.co if the error persists.</p> : null}
                <p>Before creating your first round, we'll create a Meetloaf calendar that will act as the host for your team's MeetLoaf meetings.</p>
                <p>This calendar will not impact your primary or any other existing calendars.</p>
                {this.renderButton()}
              </>
            }
          </>:
          <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: 'column' }}>
            <div className="loader"></div>
            <div className="mono-font" style={{ marginTop: 10 }}>Creating calendar</div>
          </div>
        }
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user } = state.account;
  return { user };
};

export default connect(mapStatesToProps, {
  logUserOut,
  grantCalAccess,
  setCalManagerId
})(CalendarWrite);
