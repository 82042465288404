import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'antd';
import { GoogleLogin } from 'react-google-login';
import {
  registerUserWithGoogle,
  setLoginError,
  postRequest
} from '../actions';
import googleButton from '../images/btn_google_signin_dark_normal_web@2x.png';
import googleButtonDisabled from '../images/btn_google_signin_dark_disabled_web@2x.png';

class GoogleButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      googleLoaded: false
    };
  }

  responseGoogle(response) {
    if (response.code) {
      this.props.registerUserWithGoogle(
        response.code,
        this.props.email_check ? this.props.email_check.toLowerCase() : null,
        this.props.join_hash,
        this.props.is_from_invite
      );
    } else {
      console.log("error", response);
    }
  }

  componentDidMount() {
    if (!window.gapi.auth2) {
      window.gapi.load('auth2', () => {
        this.setState({ googleLoaded: true })
      });
    } else {
      this.setState({ googleLoaded: true })
    }
  }

  render() {
    return (
      <button
        className="Login-ms" style={{ boxShadow: "none",
        background: "transparent", border: "none", marginTop: 15, padding: 0 }}
        disabled={!this.state.googleLoaded}
        onClick={() => {
          window.gapi.auth2.authorize(
            {
              client_id: "535289186539-vor6qfim3rbior7t4v7ro2j71438j08e.apps.googleusercontent.com",
              scope: "email profile",
              response_type: "code",
              include_granted_scopes: true
            }
          , (response) => {
            this.responseGoogle(response);
          });
      }}>
        <img style={{ cursor: "pointer" }} width="200" src={this.state.googleLoaded ? googleButton : googleButtonDisabled} alt="Sign in with Google" />
      </button>
    );
  }
}

export default connect(null, {
  registerUserWithGoogle,
  setLoginError,
  postRequest
})(GoogleButton);
