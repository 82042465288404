import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Input } from 'antd';
import location from '../../images/location.png';

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }

  handleChange = address => {
    this.setState({ address });
    this.props.handleChange(address);
  };

  handleSelect(address) {
    this.setState({ address: address });
    this.props.handleChange(address);
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={(address) => this.handleSelect(address)}
        style={styles.containerStyle}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ width: "100%" }}>
            <Input
              {...getInputProps({
                placeholder: 'Starbucks, 442 Geary St, San Francisco, CA 94102, USA'
              })}
              style={{ width: "100%", maxWidth: "600px" }}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: "12px 0" }
                  : { backgroundColor: '#ffffff', cursor: 'pointer', padding: "12px 0" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <img style={styles.locationPngStyle} src={location} alt="logo" />
                      <span style={{ flexGrow: "1" }}>{suggestion.description}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;

const styles = {
  containerStyle: {
    width: "600px"
  },
  resultStyle: {
    justifyContent: "center",
    height: "24px",
    display: "flex",
  },
  locationPngStyle: {
    height: "16px",
    width: "16px",
    marginRight: "20px",
    marginLeft: "20px"
  }
}
