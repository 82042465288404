import React, { Component } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import CheckList from '../../common/CheckList';
import { Select, DatePicker } from 'antd';
import UserInfo from './UserInfo';
import './JoinCompany.css';
import { connect } from 'react-redux';
import './BusinessSettings.css';
import {
  getRequest
} from '../../actions';

const { Option } = Select;
const { MonthPicker } = DatePicker;

const TIMES = ["12:00AM", "12:30AM", "1:00AM", "1:30AM", "2:00AM", "2:30AM", "3:00AM", "3:30AM", "4:00AM", "4:30AM", "5:00AM", "5:30AM", "6:00AM", "6:30AM", "7:00AM", "7:30AM", "8:00AM", "8:30AM", "9:00AM", "9:30AM", "10:00AM", "10:30AM", "11:00AM", "11:30AM", "12:00PM", "12:30PM", "1:00PM", "1:30PM", "2:00PM", "2:30PM", "3:00PM", "3:30PM", "4:00PM", "4:30PM", "5:00PM", "5:30PM", "6:00PM", "6:30PM", "7:00PM", "7:30PM", "8:00PM", "8:30PM", "9:00PM", "9:30PM", "10:00PM", "10:30PM", "11:00PM", "11:30PM"];
const WEEK_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

class BusinessHours extends Component {

  onEnterChanged(e) {
    this.props.onStartTimeChanged(e);
  }

  onLeaveChanged(e) {
    this.props.onLeaveTimeChanged(e);
  }

  onDaysChanged(checkedList) {
    this.props.onDaysChanged(checkedList);
  }

  getTimeOptions() {
    return TIMES.map((t) =>
      <Option key={t} value={t}>{t}</Option>
    );
  }

  getTimezoneSelector() {
    const options = _.sortBy(moment.tz.names(), (tz) => {
      return moment.tz(tz).utcOffset();
    });
    const optionItems = options.map((tz, idx) => {
      return <Option key={idx} value={tz}>(GMT {moment.tz(tz).format('Z')}) {tz}</Option>;
    });
    return (
      <div className="mono-font">
        <Select
          value={this.props.time_zone || moment.tz.guess()}
          onSelect={(e) => this.props.onTimeZoneChanged(e)}
          defaultValue={moment.tz.guess()}
          style={{ width: "100%", maxWidth: 400 }}
          placeholder="Select the timezone in which you typically work"
        >
          {optionItems}
        </Select>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.props.is_edit ? null : <h3 className="JoinCompany-steps-field-header" style={{ marginBottom: "30px" }}>{this.props.explainer}</h3>}
        {this.props.is_edit || true ? null : <h4 className="JoinCompany-steps-field-subheader">Schedule</h4>}
        <div className="JoinCompany-steps-field-header">  What days of the week do you typically work? </div>
        <div className="JoinCompany-check-container mono-font">
          <CheckList
            options={WEEK_DAYS}
            selected_options={this.props.selected_days}
            onChange={(checkedList) => this.onDaysChanged(checkedList)}
            display_horizontal
          />
        </div>

        <div style={{ display: "flex", alignItems: "center", marginTop: "20px", marginBottom: "20px" }}>
          <div>
            <div className="JoinCompany-steps-field-header"> When do you usually start work? </div>
            <div className="mono-font">
              <Select style={{ width: "100%" }} value={this.props.start_time} placeholder="Select time" onChange={(e) => this.onEnterChanged(e)}>
                {this.getTimeOptions()}
              </Select>
            </div>
          </div>
          <div style={{ marginLeft: "26px"}}>
            <div className="JoinCompany-steps-field-header">  When do you usually finish? </div>
            <div className="mono-font">
              <Select style={{ width: "100%" }} value={this.props.end_time} placeholder="Select time" onChange={(e) => this.onLeaveChanged(e)}>
                {this.getTimeOptions()}
              </Select>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <div className="JoinCompany-steps-field-header">Your timezone</div>
          {this.getTimezoneSelector()}
        </div>
        {
          this.props.is_edit ?
          null :
          <div>
            <UserInfo
              questionLabel
              onDepartmentChanged={(v) => this.props.onDepartmentChanged(v)}
              onJoinDateChanged={(v) => this.props.onJoinDateChanged(v)}
              onBirthdateChanged={(v) => this.props.onBirthdateChanged(v)}
              departmentId={this.props.departmentId}
              joinDate={this.props.joinDate}
              birthdate={this.props.birthdate}
              hideBday
            />
          </div>
        }

      </div>
    );
  }
}

export default connect(null, {
  getRequest
})(BusinessHours);
