import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, DatePicker, Button, Select, Modal, Switch, Popover } from 'antd';
import axios from 'axios';
import moment from 'moment';
import AddLocations from './AddLocations';
import CheckList from '../../common/CheckList';
import {
  setUserAndCompany
} from '../../actions';
import './CreateRound.css';
import CalendarWrite from '../auth/CalendarWrite';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

class CreateRound extends Component {
  constructor(props) {
    super(props);

    this.state = {
      meeting_title: "",
      meeting_description: "",
      start_date: "",
      end_date: "",
      is_loading: false,
      selected_locations: [],
      show_location_menu: false,
      check_all_locations: false,
      selected_timezone: "",
      show_confirmation: false,
      useDepartmentRules: true,
      hasWarmup: true,
      error: null,
      showSetupCalendar: !props.company.cal_manager_id
    }
  }

  getLocationString(lString) {
    if (lString.length < 40) {
      return lString;
    } else {
      return lString.substring(0,40) + "...";
    }
  }

  handleSubmit() {
    this.setState({ is_loading: true, show_confirmation: false });
    axios.post("/create_round", {
      user_id: this.props.user.id,
      company_id: this.props.company.id,
      meeting_title: this.state.meeting_title,
      meeting_description: this.state.meeting_description,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      selected_locations: this.state.selected_locations,
      time_zone: this.state.selected_timezone,
      use_department_rules: this.state.useDepartmentRules,
      has_warmup: this.state.hasWarmup
    })
    .then(response => response.data)
    .then(json => {
      console.log("create round response", json);
      if (json.success) {
        this.props.setUserAndCompany(json.user);
        this.props.history.push({
          pathname: '/rounds'
        });
      } else {
        this.setState({ is_loading: false })
        Modal.error({
          title: "Error creating round",
          content: json.message
        });
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  handleTest() {
    this.setState({ is_loading_test: true, testLink: null, testError: null });
    axios.post("/test_round", {
      user_id: this.props.user.id,
      company_id: this.props.company.id,
      meeting_title: this.state.meeting_title,
      meeting_description: this.state.meeting_description,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      selected_locations: this.state.selected_locations,
      time_zone: this.state.selected_timezone,
      use_department_rules: this.state.useDepartmentRules,
      has_warmup: this.state.hasWarmup
    })
    .then(response => response.data)
    .then(json => {
      if (json.success) {
        this.setState({ is_loading_test: false, testLink: json.message })
      } else {
        this.setState({ is_loading_test: false, testError: json.message })
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  onTitleChanged(e) {
    if (this.state.meeting_title.length > 40) return;
    this.setState({ meeting_title: e.target.value });
  }

  handleTimezoneClick(e) {
    console.log('clicked', e);
    this.setState({ selected_timezone: e });
  }

  renderTimeZone() {
    return (
      <div className="CreateRound-entry-container">
        <div className="CreateRound-entry-header">
          Time Zone
        </div>
        <div className="CreateRound-entry-description">
          The time zone the meetings will take place in.
        </div>
        <Select style={{ width: "100%" }} placeholder="Select a time zone" onChange={(e) => this.handleTimezoneClick(e)}>
          <Option value="America/New_York">Eastern Standard Time - America/New_York</Option>
          <Option value="America/Chicago">Central Standard Time - America/Chicago</Option>
          <Option value="America/Denver">Mountain Standard Time - America/Denver</Option>
          <Option value="America/Los_Angeles">Pacific Standard Time - America/Los_Angeles</Option>
        </Select>
      </div>
    )
  }

  renderTitleInput() {
    return (
      <div className="CreateRound-entry-container">
        <div className="CreateRound-entry-header">
          Meeting Title
        </div>
        <div className="CreateRound-entry-description">
          This will be the title of the calendar invite sent out to each pair.
        </div>
        <Input
          placeholder={`Enter round title (e.g. "January Meetings")`}
          value={this.state.meeting_title}
          onChange={(e) => this.onTitleChanged(e)}
        />
      </div>
    )
  }

  renderRespectDepartmentRules() {
    if (this.props.company.departments.length > 0) {
      return (
        <div className="CreateRound-entry-container">
          <div className="CreateRound-entry-header" style={{ marginBottom: 10, display: "flex", alignItems: "center" }}>
            <Switch style={{ marginRight: 5 }} checked={this.state.useDepartmentRules} onChange={(checked) => this.setState({ useDepartmentRules: checked })}/>
            Use Department Meeting Rules
          </div>
          <div className="CreateRound-entry-description">
            This round {this.state.useDepartmentRules ? "will" : "will not"} match using the department rules you've set. <a target="_blank" href="/company_settings">Click here</a> to set or review rules.
          </div>
        </div>
      );
    }
  }

  renderIncludeWarmup() {
    return (
      <div className="CreateRound-entry-container">
        <div className="CreateRound-entry-header" style={{ marginBottom: 10, display: "flex", alignItems: "center" }}>
          <Switch style={{ marginRight: 5 }} checked={this.state.hasWarmup} onChange={(checked) => this.setState({ hasWarmup: checked })}/>
          Add a warmup
        </div>
        <div className="CreateRound-entry-description">
          We'll include a randomly selected <a target="_blank" href="https://warmupz.com/?size=pair">short warmup</a> to your meeting description for your teammates to best kick off their meeting.
        </div>
      </div>
    );
  }

  renderDescriptionInput() {
    return (
      <div className="CreateRound-entry-container">
        <div className="CreateRound-entry-header">
          Meeting Description
        </div>
        <div className="CreateRound-entry-description">
          This description will be included in the invite as well. If there's something you want to be discussed in the meetings, this is a great place to let them know.
        </div>
        <TextArea
          value={this.state.meeting_description}
          onChange={(e) => this.setState({ meeting_description: e.target.value })}
          placeholder="Enter meeting description and video connection instructions (i.e. meet over Zoom, Hangouts, etc)"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </div>
    )
  }

  onDateChage(date, dateString) {
    this.setState({
      start_date: dateString[0],
      end_date: dateString[1]
    });
  }

  renderRangePicker() {
    return (
      <div className="CreateRound-entry-container">
        <div className="CreateRound-entry-header">
          Date Range
        </div>
        <div className="CreateRound-entry-description">
          Select the date range the meetings should take place.
        </div>
        <RangePicker
          format='MM/DD/YY'
          disabledDate={d => !d || d.isBefore(moment().subtract(1, 'days'))}
          onChange={(date, dateString) => this.onDateChage(date, dateString)}
          style={{ width: "100%" }}
        />
      </div>
    );
  }

  shouldBeEnabled() {
    return this.state.meeting_title.length > 0 && this.state.start_date !== "" && this.state.end_date !== "" && this.state.meeting_description !== ""
  }

  renderTest() {
    return (
      <div style={{ display: "flex", alignItems: "center", background: "#fafafc", padding: 10, marginBottom: 20 }}>
        <div style={{ paddingRight: 10 }}>
          <div className="CreateRound-entry-header">
            Test your round
          </div>
          <div className="CreateRound-entry-description">
              Send a test meeting to ensure that your calendar integration
              is working and your meeting title and description are typo free!
              This will only create an event for you and
              will not send to any of your teammates.
          </div>
          {this.state.testLink ? <div style={{marginTop: 10}}>{this.state.testLink}</div> : null}
          {this.state.testError ? <div style={{marginTop: 10, color: 'red' }}>{this.state.testError}</div> : null}
        </div>
        <Button
          type="default"
          onClick={() => this.handleTest()}
          disabled={!this.shouldBeEnabled()}
          loading={this.state.is_loading_test}
          style={{ display: "block", width: 120 }}
        >
          Send Test
        </Button>
      </div>
    )
  }

  renderSubmit() {
    return (
      <div>

      {
        !this.shouldBeEnabled() ?
        <Popover style={{ width: "100%" }} title={"Almost there!"} content={"Please complete all fields before continuing."}>
          <Button
            type="primary"
            size="large"
            onClick={() => this.setState({ show_confirmation: true })}
            disabled={!this.shouldBeEnabled()}
            loading={this.state.is_loading}
            style={{ display: "block", width: "100%" }}
          >
            Setup Meetings
          </Button>
        </Popover>
      :
        <Button
          type="primary"
          size="large"
          onClick={() => this.setState({ show_confirmation: true })}
          disabled={!this.shouldBeEnabled()}
          loading={this.state.is_loading}
          style={{ display: "block", width: "100%" }}
        >
          Setup Meetings
        </Button>
      }
      </div>
    );
  }

  onCheckAll() {
    if (this.state.check_all_locations) {
      this.setState({
        check_all_locations: false,
        selected_locations: []
      });
    } else {
      this.setState({
        check_all_locations: true,
        selected_locations: this.props.company.locations.map((l) => l.id)
      });
    }
  }

  renderLocations() {
    let button =  <Button type="primary" icon="environment" onClick={() => this.setState({ show_location_menu: true })}>
                    Add Locations
                  </Button>;

    if (this.props.company.locations.length === 0) {
      return (
        <div className="CreateRound-entry-container">
        <div className="CreateRound-entry-header">
          Meetup Locations
        </div>
        <div className="CreateRound-entry-description">
          We'll randomly assign the meetings the locations you add. For example, "Starbucks, 442 Geary St, San Francisco" or "Office Rec Room".
        </div>
          {button}
        </div>
      );
    } else {
      return (
        <div className="CreateRound-entry-container">
          <div className="CreateRound-entry-header">
            Meetup Locations
          </div>
          <div className="CreateRound-entry-description">
            We'll randomly assign the meetings the locations you add. For example, "Starbucks, 442 Geary St, San Francisco" or "Office Rec Room".
          </div>
          <Button type="link" style={{ paddingLeft: "0px" }} onClick={() => this.onCheckAll()}>
            {this.state.check_all_locations ? "Uncheck All" : "Check All"}
          </Button>
          <div className="CreateRound-checklist-container">
            <div style={{ padding: "20px" }} id="meetloaf-location-container">
              <CheckList
                options={this.props.company.locations.map((l) => ({ label: this.getLocationString(l.name), value: l.id }))}
                selected_options={this.state.selected_locations}
                onChange={(checkedList) => this.setState({ selected_locations: checkedList })}
              />
            </div>
          </div>
          <Button style={{ paddingLeft: "0px" }} type="link" icon="environment" onClick={() => this.setState({ show_location_menu: true })}>
            Add New Location
          </Button>
        </div>
      );
    }
  }

  renderLocationMenu() {
    if (this.state.show_location_menu) {
      return (
        <Modal
          title="Ad Location"
          visible={this.state.show_location_menu}
          footer={null}
          onCancel={() => this.setState({ show_location_menu: false })}
          bodyStyle={{ paddingBottom: "40px" }}
          style={{ minWidth: "500px" }}
        >
          <AddLocations
            onComplete={(location_id) => {
                this.setState({
                  show_location_menu: false,
                  selected_locations: this.state.selected_locations.concat(location_id)
                });
                console.log("selected locations", this.state.selected_locations);
                let elem = document.getElementById("meetloaf-location-container");
                console.log("elem", elem);
                let checkbox = elem.querySelectorAll(`input[value='${location_id}']`)[0]
                if (checkbox) {
                  checkbox.scrollIntoView({ block: "center" });
                }
              }
            }
          />
        </Modal>
      );
    }
  }

  renderCalendarAccess() {
    return (
      <Modal
        title="Setup MeetLoaf Host Calendar"
        visible={!this.props.company.cal_manager_id}
        footer={null}
      >
        <CalendarWrite history={this.props.history} finishingMsft={this.props.finishingMsft} code={this.props.msftCode}/>
      </Modal>
    );
  }

  renderConfirmation() {
    return (
      <Modal
        title="Confirm Round"
        visible={this.state.show_confirmation}
        onCancel={() => this.setState({ show_confirmation: false })}
        onOk={() => this.handleSubmit()}
        okText="Confirm"
      >
        <div> Are you sure you want to create this meeting round? It will schedule meetings in the specified date range and send invites for up to {this.props.company.num_members} members of your team.</div>
      </Modal>
    )
  }

  render() {
    return (
      <div className="CreateRound-container">
        <h2 className="CreateRound-header"> Create Meeting Round </h2>
        <div style={{ marginTop: "20px" }}>
          {this.renderTitleInput()}
          {this.renderDescriptionInput()}
          {this.renderIncludeWarmup()}
          {this.renderRangePicker()}
          {this.renderRespectDepartmentRules()}
          {this.renderTest()}
          {this.renderSubmit()}
          {this.renderLocationMenu()}
          {this.renderConfirmation()}
          {this.renderCalendarAccess()}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user, name, logged_in } = state.account;
  const { company } = state.company;
  return { user, name, logged_in, company };
};

export default connect(mapStatesToProps, {
  setUserAndCompany
})(CreateRound);
