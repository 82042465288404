import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../App.css';
import DisableButton from '../common/DisableButton';
import GoogleButton from'./GoogleButton';
import {
  registerUser,
  setLoginError,
  setForcedEmail,
  setIsInvite
} from '../actions';
import { validateEmail } from '../global_vars';

class Register extends Component {
  constructor(props) {
    super(props);
    let is_invite = false;
    if (props.match.params.invite_hash) {
      props.setIsInvite(true, props.match.params.invite_hash);
      is_invite = true
    }
    this.state = { first_name: "", last_name: "", email_address: props.force_email ? props.force_email : "", password: "", is_loading: false, fetching_email: is_invite && !this.props.force_email };
    props.setLoginError(false);
  }

  componentDidMount() {
    if (this.props.match.params.email) {
      console.log("we have an email");
      this.setState({ email_address: this.props.match.params.email });
      this.props.setForcedEmail(this.props.match.params.email);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.login_error != null && prevProps.login_error == null) {
      this.setState({ is_loading: false });
    }

    if (this.props.force_email && !prevProps.force_email) {
      this.setState({ fetching_email: false, email_address: this.props.force_email });
    }
  }

  handleFirstNameChange(event) {
    this.setState({ first_name: event.target.value});
  }

  handleLastNameChange(event) {
    this.setState({ last_name: event.target.value});
  }

  handleEmailChange(event) {
    const value = event.target.value.replace(/\s/g,'').toLowerCase();
    this.setState({ email_address: value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value});
  }

  shouldBeEnabled() {
    return (
      this.state.password.length > 6 && this.state.first_name.length > 0 &&
      this.state.last_name.length > 0 && validateEmail(this.state.email_address)
    );
  }

  handleSubmit() {
    this.setState({ is_loading: true });
    this.props.registerUser({
      email: this.state.email_address,
      given_name: this.state.first_name,
      family_name: this.state.last_name,
      password: this.state.password
    });
  }

  renderLoginError() {
    if (this.props.login_error != null) {
      return (
        <div style={{ color: 'red', paddingTop: '20px' }}>
          {this.props.login_error}
        </div>
      );
    }
  }

  renderEmailField() {
    if (this.props.force_email) {
      return (
        <label>
          Email Address: <br/>
          <input type="text" value={this.props.force_email} disabled style={{ backgroundColor: '#E8E8E8'}} />
        </label>
      );
    } else {
      return (
        <label>
          Email Address: <br/>
          <input type="text" value={this.state.email_address} onChange={(e) => this.handleEmailChange(e)} />
        </label>
      );
    }
  }

  renderContent() {
    if (this.state.fetching_email) {
      return (
        <div> </div>
      );
    } else {
      return (
        <div>
          <h2>Register Page</h2>
          <div>
            <div style={{ paddingBottom: '20px'}}>
              <GoogleButton
                email_check={this.props.force_email}
              />
            </div>
            <h2>OR</h2>
            <label>
              First Name: <br/>
              <input type="text" value={this.state.company_name} onChange={(e) => this.handleFirstNameChange(e)} />
            </label>
            <br/><br/>
            <label>
              Last Name: <br/>
              <input type="text" value={this.state.company_name} onChange={(e) => this.handleLastNameChange(e)} />
            </label>
            <br/><br/>
            {this.renderEmailField()}
            <br/><br/>
            <label>
              Password: <br/>
              <input type="password" value={this.state.password} onChange={(e) => this.handlePasswordChange(e)} />
            </label>
            <br/><br/>
            <DisableButton
              label="Submit"
              onClick={() => this.handleSubmit()}
              show_spinner={this.state.is_loading}
              disabled={!this.shouldBeEnabled()}
            />

            {this.renderLoginError()}
          </div>
        </div>
      );
    }
  }


  render() {
    console.log("error", this.props.login_error);
    return (
      <div className="App" style={{ paddingTop: "120px" }}>
        {this.renderContent()}
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { name, logged_in, login_error, force_email } = state.account;
  return { name, logged_in, login_error, force_email };
};

export default connect(mapStatesToProps, {
  registerUser,
  setLoginError,
  setForcedEmail,
  setIsInvite
})(Register);
