import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import DisableButton from './DisableButton';
import '../App.css';

class VerifyEmail extends Component {
  state = {
    checked_link: false,
    invalid_text: "This link is expired or invalid",
    user_id: null
  };

  componentDidMount() {
    this.checkLink();
  }

  checkLink() {
    axios.post("/verify_email", { token: this.props.match.params.token })
    .then(response => response.data)
    .then(json => {
      if (json.success) {
        this.setState({
          checked_link: true,
          is_valid_link: true
        });
      } else {
        this.setState({
          checked_link: true,
          is_valid_link: false
        });
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  handleSubmit() {
    const history = createBrowserHistory();
    history.push('/');
    window.location.reload();
  }

  renderContent() {
    if (this.state.checked_link) {
      if (!this.state.is_valid_link) {
        return (
          <div className="App" style={{ paddingTop: "120px" }}>
            {this.state.invalid_text}
          </div>
        );
      } else {
        return (
          <div>
            <h2>Email successfully verified</h2>
            <DisableButton
              label="Login"
              onClick={() => this.handleSubmit()}
            />
          </div>
        );
      }
    } else {
      return (
        <div className="App" style={{ paddingTop: "120px" }}>
          Verifying Email...
        </div>
      );
    }
  }


  render() {
    console.log("token", this.props.match.params);
    return (
      <div className="App" style={{ paddingTop: "220px" }}>
        {this.renderContent()}
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { logged_in } = state.account;
  return { logged_in };
};

export default connect(mapStatesToProps, {

})(VerifyEmail);
