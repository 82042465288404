import React from 'react';
import { Button } from 'antd';

const DisableButton = (props) => {
  if (props.show_spinner) {
    return (
      <Button
        disabled
        style={{...styles.disableStyle, ...{ marginTop: props.marginTop ? props.marginTop : "24px", minWidth: props.minWidth ? props.minWidth : "200px"}}}
      >
        {props.label}
      </Button>
    );
  } else if (props.disabled) {
    return (
      <Button
        disabled
        style={{...styles.disableStyle, ...{ marginTop: props.marginTop ? props.marginTop : "24px", minWidth: props.minWidth ? props.minWidth : "200px"}}}
      >
        {props.label}
      </Button>
    );
  } else {
    return (
      <Button
        style={{...styles.enabledStyle, ...{ marginTop: props.marginTop ? props.marginTop : "24px", minWidth: props.minWidth ? props.minWidth : "200px"}}}
        onClick={() => props.onClick()}
      >
        {props.label}
      </Button>
    );
  }
};

export default DisableButton;

const styles = {
  disableStyle: {
    backgroundColor: "#DCDCDC",
    color: "white",
    padding: "10px 62px",
    fontSize: "16px",
    borderWidth: 0,
    marginTop: "24px",
    height: "40px"
  },
  enabledStyle: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 62px",
    fontSize: "16px",
    borderWidth: 0,
    cursor: "pointer",
    marginTop: "24px",
    height: "40px"
  }
}
