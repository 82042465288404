import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PopUpForm from '../../../common/PopUpForm';
import DisableButton from '../../../common/DisableButton';
import InputField from '../../../common/InputField';

class ChangePassword extends Component {
  state = {
    current_password: "",
    password_one: "",
    password_two: "",
    is_loading: false,
    error_message: null,
    success_message: null
  }

  shouldBeEnabled() {
    return this.state.current_password.length > 4 && this.state.password_one.length > 4 && this.state.password_one === this.state.password_two;
  }

  handleSubmit() {
    this.setState({ error_message: null, is_loading: true });
    axios.post("/update_password", {
      user_id: this.props.user.id,
      current_password: this.state.current_password,
      new_password: this.state.password_one
    })
    .then(response => response.data)
    .then(json => {
      if (json.success) {
        this.setState({
          success_message: "Password Successfully Changed"
        });
        setTimeout(() => {
          this.props.onCancel();
        }, 3000);
      } else {
        this.setState({
          error_message: json.reason,
          is_loading: false
        });
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  renderErrorMessage() {
    if (this.state.error_message) {
      return (
        <div style={{ color: 'red', marginTop: '20px', textAlign: 'center' }}>
          {this.state.error_message}
        </div>
      )
    }
  }

  renderSuccess() {
    if (this.state.success_message) {
      return (
        <div style={{ color: 'green', marginTop: '20px', textAlign: 'center' }}>
          {this.state.success_message}
        </div>
      )
    }
  }

  render() {
    return (
      <PopUpForm onCancel={() => this.props.onCancel()} backgroundColor="#f5f5f7">
        <div>
          <InputField
            placeholder="Current password"
            text_value={this.state.current_password}
            onUpdate={(e) => this.setState({ current_password: e.target.value })}
            onFocus={() => null}
            maxWidth="300px"
            label="Enter Current Password"
            is_password
          />

          <InputField
            placeholder="New password"
            text_value={this.state.password_one}
            onUpdate={(e) => this.setState({ password_one: e.target.value })}
            onFocus={() => null}
            maxWidth="300px"
            label="Enter New Password"
            is_password
          />
          <InputField
            placeholder="Re-enter new password"
            text_value={this.state.password_two}
            onUpdate={(e) => this.setState({ password_two: e.target.value })}
            onFocus={() => null}
            maxWidth="300px"
            is_password
          />

          <DisableButton
            label="Sumbit"
            onClick={() => this.handleSubmit()}
            show_spinner={this.state.is_loading}
            disabled={!this.shouldBeEnabled()}
            marginTop="32px"
            minWidth="300px"
          />
          {this.renderErrorMessage()}
          {this.renderSuccess()}
        </div>
      </PopUpForm>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user } = state.account;
  return { user };
};

export default connect(mapStatesToProps, {

})(ChangePassword);
