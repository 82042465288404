// start app locally "heroku local -f Procfile.dev"
import React, { Component } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from "./reducers/index";
import AppContainer from './AppContainer';
import { setupSentry } from './global_vars';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import './App.css';

setupSentry();
library.add(fas);


class App extends Component {
  constructor(props) {
    super(props);
    if (window.location.href.includes("//www.")) {
      window.location.replace("https://app.meetloaf.co");
    }
  }
  render () {
    const store = createStore(rootReducer, applyMiddleware(thunk));

    return (
      <Provider store={store}>
        <AppContainer />
      </Provider>
    );
  }
}

export default App;
