import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Card, Icon } from 'antd';

class InviteLink extends Component {

  constructor(props) {
    super(props);
    this.state = {
      copied: props.copied
    }
  }

  renderCopied() {
    if (this.props.copied) {
      return (
        <div className="InviteBanner-copied">
          <Icon type="check"/>
          {"  "}Copied Link to Clipboard
        </div>
      )
    }
  }

  render() {
    return (
      <Card className="InviteBanner-card">
        <span style={{ marginRight: "18px" }}>{this.props.join_url}</span>
        <CopyToClipboard
          text={this.props.join_url}
          onCopy={() => this.props.onCopy()}
        >
          <Button icon="copy" />
        </CopyToClipboard>
        {this.renderCopied()}
      </Card>
    )
  }
}

export default InviteLink;
