import React, { Component } from 'react';

class NotFound extends Component {
  render () {
    console.log("path from not found", window.location.pathname);
    return <div>
      <h1>404: Not found</h1>
    </div>
  }
}

export default NotFound;
