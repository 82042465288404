import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from 'antd';
import InputField from '../../../common/InputField';
import ChangePassword from './ChangePassword';
import BusinessSettings from '../../company/BusinessSettings';
import CheckEmailUpdate from '../../../common/CheckEmailUpdate';
import SetUpBusinessHours from '../../company/SetUpBusinessHours';
import UserInfo from '../../company/UserInfo';
import {
  setLoginError,
  updateProfile
} from '../../../actions';
import '../../rounds/CreateRound.css';

class AccountSettings extends Component {
  constructor(props) {
    super(props);

    console.log("token", props.match.params.token);
    let show_email_verification = false;
    if (props.match.params.token) {
      show_email_verification = true;
    }

    this.state = {
      first_name: this.props.user.first_name,
      last_name: this.props.user.last_name,
      is_loading: false,
      show_change_password: false,
      show_change_email: false,
      show_email_verification: show_email_verification,
      show_business_hours: false,
      departmentId: this.props.user.departmentId,
      departmentName: this.props.user.departmentName,
      joinDate: this.props.user.joinDate,
      birthdate: this.props.user.birthdate,
    }
  }



  shouldBeEnabled() {
    console.log(this.state);
    // fallback in case bday or jd not defined
    let now = moment();
    return (
      (this.props.user.first_name !== this.state.first_name || this.props.user.last_name !== this.state.last_name || this.props.user.departmentId !== this.state.departmentId || (this.props.user.birthdate || now).unix() !== (this.state.birthdate || now).unix() || (this.props.user.joinDate || now).unix() !== (this.state.joinDate || now).unix()) &&
      (this.state.first_name.length > 0 && this.state.last_name.length > 0)
    );
  }

  handleSubmit() {
    this.setState({ is_loading: true });
    this.props.updateProfile({
      user_id: this.props.user.id,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      birthdate: this.state.birthdate,
      department_id: this.state.departmentId,
      join_date: this.state.joinDate
    }, () => {
      this.setState({ is_loading: false });
    });
  }

  renderAboutSection() {
    return (
      <div style={{ marginBottom: 20 }}>
        <div style={styles.headerStyle}> About </div>

        <InputField
          placeholder="Enter First Name"
          text_value={this.state.first_name}
          onUpdate={(e) => this.setState({ first_name: e.target.value })}
          onFocus={() => null}
          maxWidth="300px"
          label="First Name"
        />

        <InputField
          placeholder="Enter Last Name"
          text_value={this.state.last_name}
          onUpdate={(e) => this.setState({ last_name: e.target.value })}
          onFocus={() => null}
          maxWidth="300px"
          label="Last Name"
        />
        <div style={{ marginTop: 20, maxWidth: 300 }}>
          <UserInfo
            onDepartmentChanged={(v) => this.setState({ departmentId: v })}
            onJoinDateChanged={(v) => this.setState({ joinDate: v })}
            onBirthdateChanged={(v) => this.setState({ birthdate: v })}
            departmentId={this.state.departmentId}
            joinDate={this.state.joinDate}
            birthdate={this.state.birthdate}
            departmentName={this.state.departmentName}
          />
        </div>

        <Button
          onClick={() => this.handleSubmit()}
          disabled={!this.shouldBeEnabled()}
          loading={this.state.is_loading}
          type="primary"
          style={{ marginTop: "28px", width: "100%", maxWidth: "300px" }}
        >
          Save
        </Button>
      </div>
    );
  }

  renderPasswordOption() {
    if (this.props.user.has_password) {
      return (
        <div>
          <div className="App-input-title" style={{ marginTop: "20px" }}>Password</div>
          <div style={styles.emailButton} onClick={() => this.setState({ show_change_password: true })}>
            Change Password
          </div>
        </div>
      );
    }
  }

  renderAccountDetails() {
    return (
      <div style={{ marginTop: '40px' }}>
        <div style={styles.headerStyle}> Business Hours </div>
        <div className="CreateRound-entry-description" style={{ width: "300px", marginTop: "4px" }}>
          The typical days and hours that you're in the office. We'll only schedule meetings within these timeframes that don't conflict with your calendar.
        </div>
        <div style={{ fontSize: "14px", marginLeft: "4px", marginBottom: "12px" }}>
          <div> <b>Days of the week:</b> {this.props.user.available_days} </div>
          <div> <b>Arrive at office:</b> {this.props.user.day_start} </div>
          <div> <b>Leave office:</b> {this.props.user.day_end} </div>
        </div>

        <Button type="primary" onClick={() => this.setState({ show_business_hours: true })}>
          Edit
        </Button>

        {this.renderPasswordOption()}
      </div>
    );
  }

  renderChangePassword() {
    if (this.state.show_change_password) {
      return <ChangePassword onCancel={() => this.setState({ show_change_password: false })}/>;
    }
  }


  renderEmailVerification() {
    if (this.state.show_email_verification) {
      return (
        <CheckEmailUpdate
          token={this.props.match.params.token}
          onCancel={() => {
            this.setState({ show_email_verification: false });
            this.props.history.push({pathname: '/account_settings'})
          }}
        />
      );
    }
  }

  renderBusinessHours() {
    if (this.state.show_business_hours) {
      return (
        <SetUpBusinessHours
          user={this.props.user}
          closable
          visible={this.state.show_business_hours}
          onCancel={() => this.setState({ show_business_hours: false })}
          selected_days={this.props.user.available_days}
          is_edit
          start_time={this.props.user.day_start}
          end_time={this.props.user.day_end}
          time_zone={this.props.user.time_zone}
        />
      );
    }
  }

  render() {
    return (
      <div style={styles.containerStyle}>
        <h1 className="mono-font">Account Settings</h1>
        {this.renderAboutSection()}
        <BusinessSettings user={this.props.user} />
        <div style={{ marginTop: 20 }}>
          Need help? Contact <a href="mailto:hello@meetloaf.co">hello@meetloaf.co</a> with any questions
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user } = state.account;
  const { company } = state.company;
  return { user, company };
};

export default connect(mapStatesToProps, {
  setLoginError,
  updateProfile
})(AccountSettings);

const styles = {
  containerStyle: {
  },
  sepStyle: {
    marginLeft: '2px',
    backgroundColor: '#E0E0E0',
    height: '1px',
    width: '100%',
    marginTop: '12px'
  },
  headerStyle: {
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: "IBM Plex Mono"
  },
  emailStyle: {
    fontSize: "14px",
    marginLeft: '4px'
  },
  emailButton: {
    width: "160px",
    height: "26px",
    backgroundColor: "#216bd6",
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: "10px",
    display: 'flex',
    borderRadius: '4px',
    color: '#fff',
    fontWeight: 'bold',
    marginTop: '8px',
    cursor: 'pointer',
    marginLeft: '4px'
  }
}
