import React, { Component } from 'react';
// import CheckoutForm from './CheckoutForm';
import EnterCardContainer from './EnterCardContainer';
import CardInfoDisplay from './CardInfoDisplay';
import DisableButton from '../../../common/DisableButton';
import ConfirmModal from '../../../common/ConfirmModal';
import { connect } from 'react-redux';
import {
  setLoginError,
  resendInvite,
  cancelSubscription,
  setUserAndCompany
} from '../../../actions';

class PaymentSettings extends Component {
  state = {
    show_card_menu: false,
    show_confirm_modal: false
  }

  renderTimeLeftOnSubscribe() {
    const subscription_info = this.props.company.subscription_info;
    if (subscription_info.trial_remaining > 0) {
      return (
        <div style={{ paddingTop: "4px", fontSize: "12px" }}>
          You won't start getting charged until your trial ends on {subscription_info.trial_end_date}
        </div>
      );
    }
  }

  renderCancelDayLeft() {
    const subscription_info = this.props.company.subscription_info;
    if (subscription_info.cancel_expire_days > 0) {
      return (
        <div style={{ paddingTop: "4px", fontSize: "12px" }}>
          You'll have full access to Ahoy till the end of your current paid period on {subscription_info.cancel_expires_at}.
        </div>
      );
    }
  }

  renderTrialSection() {
    const subscription_info = this.props.company.subscription_info;
    if (this.props.company.subscribe_state === "TRIAL") {
      return (
        <div style={{ marginTop: "40px", paddingLeft: "40px" }}>
          <span> <b> Days remaining in trial period: {subscription_info.trial_remaining}</b> (expires on {subscription_info.trial_end_date}) </span>
        </div>
      );
    } else if (this.props.company.subscribe_state === "END_TRIAL") {
      return (
        <div style={{ marginTop: "40px", paddingLeft: "40px" }}>
          <h4> Your trial expired on {subscription_info.trial_end_date}. </h4>
        </div>
      );
    } else if (this.props.company.subscribe_state === "CANCELLED") {
      return (
        <div style={{ marginTop: "40px", paddingLeft: "40px" }}>
          <span> <b> You have cancelled your subscription. </b> </span>
          {this.renderCancelDayLeft()}
        </div>
      )
    } else if (this.props.company.subscribe_state === "GOOD") {
      return (
        <div style={{ marginTop: "40px", paddingLeft: "40px" }}>
          <span> <b> You are currently subscribed. </b> </span>
          {this.renderTimeLeftOnSubscribe()}
        </div>
      )
    } else if (this.props.company.subscribe_state === "CARD_ERROR") {
      return (
        <div style={{ marginTop: "40px", paddingLeft: "40px" }}>
          <span style={{ color: "red" }}> <b> There was an error processing your card. Please update your payment method to continue with your subscription. </b> </span>
        </div>
      )
    }
  }

  renderCardSection() {
    return (
      <CardInfoDisplay
        card_info={this.props.company.card_info}
        onChangeCardPressed={() => this.setState({ show_card_menu: true })}
      />
    );
  }

  renderChargeClarification() {
    const subscription_info = this.props.company.subscription_info;
    if (this.props.company.subscribe_state === "TRIAL") {
      return <div style={{ paddingTop: "8px", fontSize: '12px'}}>You won't be charged till your trial ends on {subscription_info.trial_end_date}. Your subscription will commence on that date. </div>;
    }
  }

  renderSubscribeSection() {
    if (this.props.company.subscription_info.show_subscribe) {
      // show subscribe button
      return (
        <div style={{ marginTop: "40px", marginLeft: "40px", padding: "30px", backgroundColor: "#fff", maxWidth: "600px" }}>
          <div> $10.00 / month </div>
          <DisableButton
            label="Subscribe Now"
            onClick={() => this.setState({ show_card_menu: true })}
            show_spinner={this.state.is_loading}
          />
          {this.renderChargeClarification()}
        </div>
      );
    } else if (this.props.company.card_info.id) {
      return this.renderCardSection();
    }
  }

  renderCardMenu() {
    if (this.state.show_card_menu) {
      return (
        <EnterCardContainer
          onCancel={() => this.setState({ show_card_menu: false })}
          onSuccess={() => this.setState({ show_card_menu: false })}
        />
      );
    }
  }

  onCancelConfirm(val) {
    this.props.cancelSubscription({ company_id: this.props.company.id }, (json) => {
      this.props.setUserAndCompany(json.user);
      this.setState({ show_confirm_modal: false });
    });
  }

  renderCancel() {
    if (this.props.company.subscribe_state === "GOOD" || this.props.company.subscribe_state === "CARD_ERROR") {
      return (
        <button style={{ margin: "40px" }} onClick={() => this.setState({ show_confirm_modal: true })}>
          Cancel Subscription
        </button>
      );
    }
  }

  renderConfirmModal() {
    if (this.state.show_confirm_modal) {
      return (
        <ConfirmModal
          onCancel={() => this.setState({ show_confirm_modal: false })}
          onConfirm={() => this.onCancelConfirm()}
          description="Are you sure you want to cancel your subscription? You won't be able to generate letters after the end of your paid period."
        />
      );
    }
  }

  render() {
    return (
      <div>
        {this.renderTrialSection()}
        {this.renderSubscribeSection()}
        {this.renderCancel()}
        {this.renderCardMenu()}
        {this.renderConfirmModal()}
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user } = state.account;
  const { company } = state.company;
  return { user, company };
};

export default connect(mapStatesToProps, {
  setLoginError,
  resendInvite,
  cancelSubscription,
  setUserAndCompany
})(PaymentSettings);
