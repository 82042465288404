import React, { Component } from 'react';
import { Checkbox } from 'antd';

class CheckList extends Component {
  onChange(checkedValue) {
    //console.log("selected check", checkedValue);
    this.props.onChange(checkedValue);
  }

  render() {
    return (
      <Checkbox.Group
        options={this.props.options}
        value={this.props.selected_options}
        onChange={(v) => this.onChange(v)}
      />
    );
  }
}

export default CheckList;
