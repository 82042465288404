import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import './common/spinner.css';
import HomeRouter from './home/navigation/router';
import LandingRouter from './landing/navigation/router';
import {
  checkAuthorized
} from './actions';

const history = createBrowserHistory();

class AppContainer extends Component {

  componentDidMount() {
    this.props.checkAuthorized();
  }

  renderContent() {
    if (this.props.has_check_authorized) {
      if (this.props.logged_in) {
        return (
          <div style={{ height: "100%"}}>
            <div className="logged-in-page page-wrap">
              <HomeRouter history={history}/>
            </div>
          </div>
        );
      } else {
        return (
          <div style={{ height: "100%"}}>
            <div className="page-wrap">
              <LandingRouter history={history}/>
            </div>
            <footer className="site-footer">
              © {new Date().getFullYear()} TSH | Made in San Francisco
            </footer>
          </div>
        );
      }
    } else {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
          <div className="loader"></div>
        </div>
      );
    }
  }

  render() {
    return this.renderContent()
  }
}

const mapStatesToProps = (state) => {
  const { logged_in, has_check_authorized } = state.account;
  return { logged_in, has_check_authorized };
};

export default connect(mapStatesToProps, {
  checkAuthorized
})(AppContainer);
