import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';

class NavBar extends Component {
  render() {
    return (
      <div style={{ paddingTop: "8px" }}>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <span style={{ padding: "20px"}}>
            <Link to={'/'} className="nav-link"> Login </Link>
          </span>
        </nav>
        <hr />
      </div>
    );
  }
}

export default NavBar;;
