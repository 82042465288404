import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import CheckEmailUpdate from '../common/CheckEmailUpdate';
import GoogleButton from'./GoogleButton';
import MicrosoftButton from'./MicrosoftButton';
import '../common/spinner.css';
import { Card, Button } from 'antd';
import '../App.css';
import './Login.css'
import {
  loginUser,
  setLoginError,
  registerUserWithGoogle,
  registerUserWithMicrosoft,
  setForcedEmail,
  setIsInvite,
  getCompanyNameFromJoinHash
} from '../actions';
import { validateEmail } from '../global_vars';
import logo from '../images/mlogo.png';
import meetMobileImg from '../images/meet-mobile.png';
import meetDesktopImg from '../images/meet-desktop.png';

class Login extends Component {
  constructor(props) {
    super(props);
    let is_invite = false;
    let is_join = false;
    if (props.match.params.invite_hash) {
      props.setIsInvite(true, props.match.params.invite_hash);
      is_invite = true;
    }

    if (props.match.params.join_hash) {
      props.getCompanyNameFromJoinHash({join_hash: props.match.params.join_hash});
      is_join = true;
    }

    let msftLoading = false;
    let msftCode = null;
    let msftEmail = null;
    let msftJoinHash = null;
    let qString = queryString.parse(window.location.search);
    const [isMicrosoft, stateEmail, stateJoinHash] = _.split(qString.state, ",");
    if (isMicrosoft && qString.code != null) {
      msftLoading = true
      msftCode = qString.code;
      msftEmail = stateEmail && stateEmail.length > 0 ? stateEmail : null;
      msftJoinHash = stateJoinHash && stateJoinHash.length > 0 ? stateJoinHash : null;
    }

    // console.log("token", props.match.params.join_hash);
    let show_email_verification = false;
    if (props.match.params.token) {
      show_email_verification = true;
    }

    this.state = {
      email_address: props.force_email ? props.force_email : msftEmail ? msftEmail : "",
      password: "",
      is_loading: false,
      msftLoading,
      msftCode,
      fetching_join: is_join && !this.props.invite_company_name,
      fetching_email: is_invite && !this.props.force_email,
      show_email_verification: show_email_verification,
      join_hash: props.match.params.join_hash || msftJoinHash,
      is_from_invite: props.match.params.join_hash != null || is_invite || msftEmail
    };
    props.setLoginError(false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.login_error != null && prevProps.login_error == null) {
      this.setState({ is_loading: false, msftLoading: false });
    }

    if (this.props.force_email && !prevProps.force_email) {
      this.setState({ fetching_email: false, email_address: this.props.force_email });
    }

    if (this.props.invite_company_name && !prevProps.invite_company_name) {
      this.setState({ fetching_join: false });
    }
  }

  componentDidMount() {
    console.log(this.state.msftCode)
    if (this.state.msftCode) {
      this.props.registerUserWithMicrosoft(
        this.state.msftCode,
        this.state.email_addresss ? this.state.email_addresss.toLowerCase() : null,
        this.state.join_hash,
        this.state.is_from_invite
      );
    }
    // if (this.props.match.params.invite_hash) {
    //   //this.setState({ email_address: this.props.match.params.email });
    //   //this.props.setForcedEmail(this.props.match.params.email);
    // }
  }

  handleEmailChange(event) {
    const value = event.target.value.replace(/\s/g,'').toLowerCase();
    this.setState({ email_address: value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value});
  }

  shouldBeEnabled() {
    return (
      this.state.password.length > 3 && validateEmail(this.state.email_address)
    );
  }

  handleSubmit() {
    this.setState({ is_loading: true });
    this.props.loginUser({
      email: this.state.email_address,
      hello_kitty: this.state.password
    });
  }

  renderLoginError() {
    if (this.props.login_error != null) {
      return (
        <div style={{ color: 'red', paddingTop: '20px' }}>
          {this.props.login_error}
        </div>
      );
    }
  }

  renderEmailField() {
    if (this.props.force_email) {
      return (
        <label>
          Email Address: <br/>
          <input type="text" value={this.props.force_email} disabled style={{ backgroundColor: '#E8E8E8'}} />
        </label>
      );
    } else {
      return (
        <label>
          Email Address: <br/>
          <input type="text" value={this.state.email_address} onChange={(e) => this.handleEmailChange(e)} />
        </label>
      );
    }
  }

  renderContent() {
    if (this.state.fetching_email || this.state.fetching_join) {
      return (
        <div> </div>
      );
    } else if (this.state.msftLoading) {
      return (
        <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: 'column' }}>
          <div className="loader"></div>
          <div className="mono-font" style={{ marginTop: 10 }}>Logging in</div>
        </div>
      );
    } else {
      return (
        <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: 'row' }}>
          <div style={{ textAlign: "left" }}>
            <h1 className="mono-font">Welcome!</h1>
            <h3 style={{ fontWeight: 400 }}>
              {
                this.props.invite_company_name ?
                <span>You've been invited to join Meetloaf at {this.props.invite_company_name}!<br/> Meetloaf sets up you and your teammates to better get to know eachother.<br/></span> :
                <span>Ready to meet?<br/></span>
              }
            </h3>
            <div style={{ paddingBottom: '20px', paddingTop: '12px', lineHeight: '14px'}}>
              <GoogleButton
                email_check={this.props.force_email}
                join_hash={this.state.join_hash}
                is_from_invite={this.state.is_from_invite}
              />
              <MicrosoftButton
                email_check={this.props.force_email || this.state.email_address}
                join_hash={this.state.join_hash}
                is_from_invite={this.state.is_from_invite}
              />
            </div>
            <div style={{ width: "100%" }}>
              Already a member? Click the above service you signed up with to login.
            </div>
          </div>
        </div>
      );
    }
  }

  renderEmailVerification() {
    if (this.state.show_email_verification) {
      return (
        <CheckEmailUpdate
          token={this.props.match.params.token}
          onCancel={() => {
            this.setState({ show_email_verification: false });
            this.props.history.push({pathname: '/login'})
          }}
        />
      );
    }
  }

  renderTermsPrivacy() {
    return (
      <div className="Login-terms-container">
        <Button
          className="Login-button-fonts"
          style={{ marginRight: "13px" }}
          type="link"
          onClick={() => this.props.history.push({ pathname: '/privacy' })}
        >
            Privacy Policy
        </Button>
        <li style={{ marginTop: "8px", color: "#A0A0A0" }} />
        <Button
          className="Login-button-fonts"
          type="link"
          onClick={() => this.props.history.push({ pathname: '/terms' })}
        >
          Terms of Service
        </Button>
      </div>
    );
  }

  render() {
    console.log("forced email", this.props.force_email);
    return (
      <div className="App" style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: 'column', padding: 20}}>
        <img src={logo} alt="logo" style={{ width: "240px", marginTop: "50px", marginBottom: "36px" }} />
        <Card className="Login-card">
          <div style={{ flexDirection: "column", alignItems: "center", display: "flex", width: "100%" }}>
            {this.renderContent()}
            {this.renderLoginError()}
          </div>
          <div style={{ width: "100%" }}>
            <img src={meetDesktopImg} className="Login-desktop-img" alt="Login"/>
            <img src={meetMobileImg} className="Login-mobile-img" alt="Login" />
          </div>
        </Card>
        {this.renderTermsPrivacy()}
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { name, logged_in, login_error, force_email } = state.account;
  const { invite_company_name } = state.company;
  return { name, logged_in, login_error, force_email, invite_company_name };
};

export default connect(mapStatesToProps, {
  loginUser,
  setLoginError,
  registerUserWithGoogle,
  registerUserWithMicrosoft,
  setForcedEmail,
  setIsInvite,
  getCompanyNameFromJoinHash
})(Login);
