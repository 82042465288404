import {
  SET_COMPANY_NAME,
  UPDATE_SESSION,
  SET_USER,
  SET_LOGIN_ERROR,
  SET_FORCE_EMAIL,
  SET_IS_INVITE,
  SET_ACCOUNT_MENU
} from '../actions/types';
import USER from '../models/user';

const INITIAL_STATE = {
  logged_in: false,
  has_check_authorized: false,
  name: "NOT SET",
  domain: "",
  user: null,
  login_error: null,
  force_email: null,
  is_invite: false,
  show_account_menu: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      const new_user = new USER(action.payload);
      return { ...state, user: new_user };
    case SET_LOGIN_ERROR:
      return { ...state, login_error: action.payload };
    case SET_COMPANY_NAME:
      return { ...state, name: action.payload };
    case UPDATE_SESSION:
      return { ...state, logged_in: action.payload, has_check_authorized: true };
    case SET_FORCE_EMAIL:
      return { ...state, force_email: action.payload };
    case SET_IS_INVITE:
      return { ...state, is_invite: action.payload };
    case SET_ACCOUNT_MENU:
      return { ...state, show_account_menu: action.payload };
    default:
      return state;
  }
};
