import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import {
  logUserOut
} from '../../actions';

class Logout extends Component {
  onLogout() {
    this.props.logUserOut(() => {
      
    });
  }

  render () {
    return (
      <div style={{ padding: '150px' }}>
        <h1>Logout Page</h1>
        <button onClick={() => this.onLogout()}>
          Logout
        </button>
      </div>
    );
  }
}

export default connect(null, {
  logUserOut
})(Logout);
