import React, { Component } from 'react';
import axios from 'axios';
import '../App.css';
import DisableButton from '../common/DisableButton';

class PasswordReset extends Component {
  state = {
    password_one: "",
    password_two: "",
    success_reset: false,
    checked_link: false,
    is_valid_link: false,
    invalid_text: "This link is expired or invalid",
    user_id: null
  };

  componentDidMount() {
    this.checkLink();
  }

  checkLink() {
    axios.post("/check_reset_link", { token: this.props.match.params.token })
    .then(response => response.data)
    .then(json => {
      if (json.success) {
        this.setState({
          checked_link: true,
          is_valid_link: true,
          user_id: json.user_id
        });
      } else {
        this.setState({
          checked_link: true,
          is_valid_link: false,
        });
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  handleSubmit() {
    axios.post("/res_pwd", { user_id: this.state.user_id, password: this.state.password_one })
    .then(response => response.data)
    .then(json => {
      if (json.success) {
        this.setState({
          success_reset: true
        });
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  shouldBeEnabled() {
    return this.state.password_one.length > 6 && this.state.password_one === this.state.password_two;
  }

  renderInvalidContent() {
    return (
      <div className="App" style={{ paddingTop: "120px" }}>
        {this.state.invalid_text}
      </div>
    );
  }

  renderValidContent() {
    if (this.state.success_reset) {
      return (
        <div className="App" style={{ paddingTop: "120px" }}>
          <h2>Password Successfully Reset</h2>
          <DisableButton
            label="Login To Account"
            onClick={() => this.props.history.push({pathname: '/login'})}
          />
        </div>
      );
    } else {
      return (
        <div className="App" style={{ paddingTop: "120px" }}>
          <h2>Reset Password</h2>
          <div>
            <label>
              Password: <br/>
              <input type="password" value={this.state.password_one} onChange={(e) => this.setState({ password_one: e.target.value })} />
            </label>
            <br/><br/>
            <label>
              Password Confirm: <br/>
              <input type="password" value={this.state.password_two} onChange={(e) => this.setState({ password_two: e.target.value })} />
            </label>
            <br/><br/>
            <DisableButton
              disabled={!this.shouldBeEnabled()}
              label="Submit"
              onClick={() => this.handleSubmit()}
            />
          </div>
        </div>
      );
    }
  }

  render() {
    //console.log("token", this.props.match.params.token);
    if (this.state.checked_link) {
      if (this.state.is_valid_link) {
        return this.renderValidContent();
      } else {
        return this.renderInvalidContent();
      }
    } else {
      return <div></div>;
    }
  }
}

export default PasswordReset;
