import React, { Component } from 'react';
import { Button, Modal } from 'antd';
import './NewRoundBanner.css';
import firstRound from '../../images/first-meet.png';
import CalendarWrite from '../auth/CalendarWrite';

class NewRoundBanner extends Component {

  state = {
    visible: false,
    copied: false,
    showSetupCalendar: false
  }

  onCreateClicked() {
    if (this.props.hasRounds) {
      this.props.history.push({
        pathname: '/create_round'
      });
    } else {
      this.setState({ showSetupCalendar: true });
    }
  }

  renderCalendarAccess() {
    if (this.state.showSetupCalendar || (this.props.finishingMsft)) {
      return (
        <Modal
          title="Setup MeetLoaf Host Calendar"
          visible={this.state.showSetupCalendar || (this.props.finishingMsft)}
          onCancel={() => {
            this.props.history.replace({
              pathname: '/'
            });
            window.location.reload();
          }}
          footer={null}
        >
          <CalendarWrite history={this.props.history} finishingMsft={this.props.finishingMsft} code={this.props.msftCode}/>
        </Modal>
      );
    }
  }

  render() {
    return (
      <div>
        {this.renderCalendarAccess()}
        <div className="NewRoundBanner-container">
          <img src={firstRound} className="NewRoundBanner-img" />

          <div className="NewRoundBanner-text-container">
            <div>
              <div className="NewRoundBanner-header">Create a new round</div>
              <div>Create your next set of virtual meetups.</div>
            </div>
            <div className="NewRoundBanner-button-container">
              <Button type="primary" size="large" onClick={() => this.onCreateClicked()}>
                + New Round
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NewRoundBanner;
