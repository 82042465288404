import React, { Component } from 'react';
import './PageWrapper.css';

class PageWrapper extends Component {
  render() {
    return (
      <div className="PageWrapper-container">
        {this.props.children}
      </div>
    );
  }
}

export default PageWrapper
