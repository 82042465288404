import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popconfirm, Spin, Table, Tag } from 'antd';
import Media from "react-media";
import axios from 'axios';
import '../rounds/ViewRound.css';
import NewMemberBanner from './NewMemberBanner';
import {
  setNumMembers
} from '../../actions';

const pageSize = 30;

class Members extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fetchedInfo: false,
      members: []
    }
  }

  componentDidMount() {
    this.getMembers();
  }

  deleteMember(id) {
    axios.post(`/delete_user/${id}`)
    .then(response => response.data)
    .then(json => {
      this.getMembers();
    })
    .catch(error => {
      console.log(error);
    });
  }

  getMembers() {
    this.setState({ fetchedInfo: false })
    axios.get("/get_members", {
      params: {
        company_id: this.props.company.id
      }
    })
    .then(response => response.data)
    .then(json => {
      console.log("round info", json);
      this.setState({
        fetchedInfo: true,
        members: json.members
      });
      this.props.setNumMembers(json.members.length);
    })
    .catch(error => {
      console.log(error);
    });
  }

  getColumns(isLarge) {
    let columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render(text, record) {
          return {
          	children: <div style={{ color: "#222", fontWeight: 600 }}><b>{text}</b></div>,
          };
        }
      }
    ];
    if (isLarge) {
      columns = columns.concat([
        {
          title: 'Available Days',
          dataIndex: 'days',
          key: 'days',
          render(text, record) {
            return {
            	children: <div style={{ fontFamily: "IBM Plex Mono"}}>{record.has_set_business_hours ? record.days.map((d,i) => <Tag key={i}>{d}</Tag>) : "Has Not Setup..."}</div>,
            };
          }
        },
        {
          title: 'Available Hours',
          dataIndex: 'hours',
          key: 'hours',
          render(text, record) {
            return {
            	children: <div style={{ fontFamily: "IBM Plex Mono"}}>{text}</div>,
            };
          }
        }
      ]);
    }

    columns = columns.concat([
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: (text, record) => {
          if (this.props.user.id !== record.key) {
            return (
              <Popconfirm
                title="Are you sure delete this person?"
                onConfirm={() => this.deleteMember(record.key)}
                okText="Yes"
                cancelText="No"
              >
                <div style={{ cursor: "pointer", fontFamily: "IBM Plex Mono", fontSize: 18 }}>×</div>
              </Popconfirm>
            )
          }
        }
      }
    ]);
    return columns;
  }

  renderMembers(isLarge) {
    let data_array = [];

    this.state.members.forEach((r) => {
      let days = "Has Not Setup...";
      let hours = "Has Not Setup...";
      if (r.has_set_business_hours) {
        days = r.days_array;
        hours = `${r.day_start} - ${r.day_end}`
      }
      const r_obj = {
        key: r.id,
        name: r.first_name + " " + r.last_name,
        days: days,
        hours: hours,
        has_set_business_hours: r.has_set_business_hours
      };
      data_array.push(r_obj)
    });
    return (
      <Table
        columns={this.getColumns(isLarge)}
        dataSource={data_array}
        pagination={data_array.length > pageSize && { pageSize }}
        style={{ maxWidth: "1020px" }}
      />
    );
  }

  renderHeaderInfo() {
    return (
      <div style={{ marginBottom: "40px" }}>
        <div style={{ display: "flex" }}>
          <h1 style={{ fontFamily: "IBM Plex Mono", fontWeight: 600, flexGrow: 1 }}>Members ({this.props.company.num_members})</h1>
        </div>
        <NewMemberBanner join_url={this.props.company.join_url} />
      </div>
    );
  }

  render() {
    if (this.state.fetchedInfo) {
      return(
        <Media query="(min-width: 599px)">
          {matches => {
            return (
              <div className="ViewRound-container">
                {this.renderHeaderInfo()}
                <div className="ViewRound-table-container">
                  {this.renderMembers(matches)}
                </div>
              </div>
            );
          }}
        </Media>
      );
    } else {
      return (
        <div className="ViewRound-spinner">
          <Spin size="large" />
        </div>
      );
    }
  }
}

const mapStatesToProps = (state) => {
  const { company } = state.company;
  const { user } = state.account;
  return { company, user };
};

export default connect(mapStatesToProps, {
  setNumMembers
})(Members);
