import React from 'react';

const PrivacyPolicy = (props) => {
  return (
    <div style={{ padding: "40px" }}>
      <strong>Privacy Policy</strong>
      <br/><br/>
      Protecting your private information is our priority. This Statement of Privacy applies to meetloaf.co and TSH, LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to TSH, LLC include meetloaf.co. The TSH, LLC website is a Ongoing cultural development site. By using the TSH, LLC website, you consent to the data practices described in this statement.
      <br/><br/>
      Collection of your Personal Information
      In order to better provide you with products and services offered on our Site, TSH, LLC may collect personally identifiable information, such as your:
       <br/>
       -	First and Last Name <br/>
       -	E-mail Address
      <br/><br/>
      We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.
      <br/><br/>
      Use of your Personal Information
      TSH, LLC collects and uses your personal information to operate its website(s) and deliver the services you have requested.
      <br/><br/>
      TSH, LLC may also use your personally identifiable information to inform you of other products or services available from TSH, LLC and its affiliates.
      <br/><br/>
      Sharing Information with Third Parties
      TSH, LLC does not sell, rent or lease its customer lists to third parties.
      <br/><br/>
      TSH, LLC may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to TSH, LLC, and they are required to maintain the confidentiality of your information.
      <br/><br/>
      TSH, LLC may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on TSH, LLC or the site; (b) protect and defend the rights or property of TSH, LLC; and/or (c) act under exigent circumstances to protect the personal safety of users of TSH, LLC, or the public.
      <br/><br/>
      Automatically Collected Information
      Information about your computer hardware and software may be automatically collected by TSH, LLC. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the TSH, LLC website.
      <br/><br/>
      Security of your Personal Information
      TSH, LLC secures your personal information from unauthorized access, use, or disclosure. TSH, LLC uses the following methods for this purpose:
      <br/>
       -	SSL Protocol
      <br/><br/>
      When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.
      <br/><br/>
      We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.
      <br/><br/>
      Children Under Thirteen
      TSH, LLC does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.
      <br/><br/>
      Disconnecting your TSH, LLC Account from Third Party Websites
      You will be able to connect your TSH, LLC account to third party accounts. BY CONNECTING YOUR TSH, LLC ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE. You may disconnect your account from a third party account at any time. To unlink MeetLoaf with your calendar account, login to your google account and go to https://myaccount.google.com/u/0/permissions. Select "MeetLoaf" and click the "Remove Access" button.
      <br/><br/>
      E-mail Communications
      From time to time, TSH, LLC may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication.
      <br/><br/>
      Changes to this Statement
      TSH, LLC reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
      <br/><br/>
      Contact Information
      TSH, LLC welcomes your questions or comments regarding this Statement of Privacy. If you believe that TSH, LLC has not adhered to this Statement, please contact TSH, LLC at:
      <br/><br/>
      TSH, LLC
      401 Harrison St
      San Francisco, California 94105
      <br/><br/>
      Email Address:
      hello@meetloaf.co
      <br/><br/>
      Effective as of January 28, 2020
    </div>
  );
};

export default PrivacyPolicy;
