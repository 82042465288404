import React, { Component } from 'react';
import visa from '../../../images/visa.png';
import master from '../../../images/mastercard2.jpg';
import amex from '../../../images/amex.png';
import discover from '../../../images/discover.png';
import card_default from '../../../images/card-default.png';

class CardInfoDisplay extends Component {

  renderLogo() {
    // return (
    //   <div style={{ width: "30px", marginRight: "18px" }}>
    //     <img className="visa-logo" src={card_default} alt="logo" />
    //   </div>
    // );

    switch(this.props.card_info.card_brand) {
      case "Visa":
        return (
          <div style={{ width: "30px", marginRight: "18px" }}>
            <img className="visa-logo" src={visa} alt="logo" />
          </div>
        );
      case "MasterCard":
        return (
          <div style={{ width: "40px", marginRight: "18px" }}>
            <img className="visa-logo" src={master} alt="logo" />
          </div>
        );
      case "American Express":
        return (
          <div style={{ width: "30px", marginRight: "18px" }}>
            <img className="visa-logo" src={amex} alt="logo" />
          </div>
        );
      case "Discover":
        return (
          <div style={{ width: "36px", marginRight: "18px" }}>
            <img className="visa-logo" src={discover} alt="logo" />
          </div>
        );
      default:
        return (
          <div style={{ width: "30px", marginRight: "18px" }}>
            <img className="visa-logo" src={card_default} alt="logo" />
          </div>
        );
    }
  }

  render() {
    console.log(this.props.card_info);
    return (
      <div style={styles.containerStyle}>
        <div className="brand-and-info-container">
          {this.renderLogo()}
          <div>
            <div style={{ fontSize: "13px" }}>
              This account is billed to
            </div>
            <div style={{ fontSize: "14px", fontWeight: 'bold' }}>
              {this.props.card_info.card_brand} card ending in {this.props.card_info.card_last4}
            </div>
          </div>
        </div>
        <div>
          <button style={styles.changeCardStyle} onClick={() => this.props.onChangeCardPressed()}>
            Change Card
          </button>
        </div>
      </div>
    );
  }
}

export default CardInfoDisplay;

const styles = {
  containerStyle: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "14px 10px 14px 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    maxWidth: "660px",
    marginLeft: "40px",
    marginRight: "40px",
    marginTop: "30px",
    border: "1px solid #C8C8C8"
  },
  changeCardStyle: {
    backgroundColor: "#171d22",
    color: "#fff",
    fontSize: "13px",
    justifyContent: 'center',
    alignItems: 'center',
    padding: "8px 16px 8px 16px",
    borderRadius: "4px",
    cursor: "pointer"
  }
}
