import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { validateEmail } from '../../../global_vars';
import {
  removeCollaborator
} from '../../../actions';

class RemoveConfirm extends Component {
  state = { email: "", is_loading: false }

  componentDidUpdate(prevProps) {
    if (this.props.login_error != null && prevProps.login_error == null) {
      this.setState({ is_loading: false });
    }
  }

  shouldBeEnabled() {
    return (
      validateEmail(this.state.email)
    );
  }

  handleSubmit() {
    this.props.removeCollaborator({
      user_id: this.props.user.id,
      collaborator_id: this.props.collaborator.id
    }, () => { this.props.onSuccess()});
  }

  onCancel() {
    this.props.onCancel();
  }

  render() {
    return (
      <Modal
        title="Remove Collaborator"
        visible={this.props.visible}
        onOk={() => this.handleSubmit()}
        onCancel={() => this.onCancel()}
        okText="Confirm"
      >
        <h4>Are you you want to remove {this.props.collaborator.name}</h4>
      </Modal>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user, login_error } = state.account;
  const { company } = state.company;
  return { user, company, login_error };
}

export default connect(mapStatesToProps, {
  removeCollaborator
})(RemoveConfirm);
