import React, { Component } from 'react';
import BusinessSettings from '../company/BusinessSettings';
import meeterImage from '../../images/meeter-success.png';
class MeeterHome extends Component {
  renderContent() {
    if (this.props.user.has_set_business_hours) {
      return (
        <div>
          <img src={meeterImage} style={{ width: "100%", maxWidth: 320 }} />
          <div className="BusinessSettings-main-header"> All Set! </div>
          <div className="BusinessSettings-sub-header">
            <p>Thanks for syncing your schedule. You'll start seeing meetings with
            your teammates on your calendar soon.</p>
            <p>
              <a href="/account_settings">Click here</a> if you need to update your availability
            </p>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  render() {
    return (
      this.renderContent()
    );
  }
}

export default MeeterHome;
