import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { setLoggedIn } from './actions';

export const setupSentry = () => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({dsn: "https://b7c85a1b07e3426282bdbf41cad5f598@sentry.io/2772772"});
  }
}

export const msRedirect = process.env.NODE_ENV === 'development' ? "http://localhost:3000/" : "https://app.meetloaf.co";

export const getAPIRequest = (path, params={}, onSuccess) => {
  return (dispatch) => {
    axios.get(path, { withCredentials: true, params: params })
    .then(response => response.data)
    .then(json => {
      if (!json.success && json.reason === "expired") {
        dispatch(setLoggedIn(false));
      } else {
        onSuccess(json);
      }
    })
    .catch(error => {
      console.log(error);
    });
  }
};

export const postAPIRequest = (path, data, onSuccess) => {
  return (dispatch) => {
    axios.post(path, { withCredentials: true, ...data })
    .then(response => response.data)
    .then(json => {
      if (!json.success && json.reason === "expired") {
        dispatch(setLoggedIn(false));
      } else {
        onSuccess(json);
      }
    })
    .catch(error => {
      console.log(error);
    });
  }
};

export const validateEmail = (email) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
