import {
  SET_COMPANY,
  SET_ROUNDS,
  SET_DEPARTMENTS,
  SET_INVITE_COMPANY_NAME
} from '../actions/types';
import COMPANY from '../models/company';

const INITIAL_STATE = {
  company: null,
  invite_company_name: null,
  rounds: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COMPANY: {
      console.log("creating new company", action.payload);
      const new_company = new COMPANY(action.payload);
      return { ...state, company: new_company };
    }
    case SET_DEPARTMENTS: {
      const new_company = { ...state.company };
      new_company.departments = action.payload;
      return { ...state, company: new_company };
    }
    case SET_INVITE_COMPANY_NAME: {
      return { ...state, invite_company_name: action.payload }
    }
    case SET_ROUNDS:
      return { ...state, rounds: action.payload }
    default:
      return state;
  }
};
