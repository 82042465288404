import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import {
  logUserOut,
  setUserAndCompany
} from '../../actions';

class ShowInvite extends Component {

  state = { viewing: "none" };

  onLogout() {
    this.props.logUserOut(() => {
      const history = createBrowserHistory();
      history.push('/');
      window.location.reload();
    });
  }

  onInviteResponse(response) {
    axios.post("/invite_response", {
      user_id: this.props.user.id,
      company_id: this.props.user.invite_info.company_id,
      invite_id: this.props.user.invite_info.id,
      response: response
    })
    .then(response => response.data)
    .then(json => {
      if (json.success) {
        this.props.setUserAndCompany(json.user);
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  render() {
    console.log("user from invite", this.props.user);
    const invite_info = this.props.user.invite_info;
    return (
      <div style={styles.containerStyle}>
        <div style={styles.displayContainer}>
          <h2>Invite</h2>

          <div>
            {invite_info.inviter_name} has invited you to join {invite_info.company_name}.
          </div>

          <button style={{ marginTop: "20px" }} onClick={() => this.onInviteResponse("YES")}>
            Accept
          </button>

          <button style={{ marginTop: "20px" }} onClick={() => this.onInviteResponse("NO")}>
            Decline
          </button>

          <button style={styles.logoutContainer} onClick={() => this.onLogout()}>
            Logout
          </button>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user } = state.account;
  const { company } = state.company;
  return { user, company };
};

export default connect(mapStatesToProps, {
  logUserOut,
  setUserAndCompany
})(ShowInvite);

const styles = {
  containerStyle: {
    position: 'fixed',
    display: 'flex',
    left: 0, right: 0, top: 0, bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px'
  },
  displayContainer: {
    backgroundColor: '#fff',
    maxWidth: '600px',
    width: '100%',
    borderRadius: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: "50px 0px",
    position: 'absolute'
  },
  logoutContainer: {
    position: 'absolute',
    top: "12px",
    right: '12px',
    backgroundColor: 'rgba(0,0,0,0)',
    borderWidth: "0px",
    color: 'red',
    fontSize: "12px",
    cursor: "pointer"
  }

}
