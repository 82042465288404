import {
  getAPIRequest,
  postAPIRequest
} from '../global_vars';

// Wrapper for requests
export const getRequest = (path, params, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(getAPIRequest(path, params, onSuccess, onFail));
  };
}

export const postRequest = (path, data, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(postAPIRequest(path, data, onSuccess, onFail));
  }
}
