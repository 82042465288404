import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Input } from 'antd';
import { validateEmail } from '../../../global_vars';
import {
  inviteUser,
  setLoginError
} from '../../../actions';

class AddCollaborator extends Component {
  state = { email: "", is_loading: false }

  componentDidUpdate(prevProps) {
    if (this.props.login_error != null && prevProps.login_error == null) {
      this.setState({ is_loading: false });
    }
  }

  shouldBeEnabled() {
    return (
      validateEmail(this.state.email)
    );
  }

  handleSubmit() {
    this.setState({ is_loading: true })
    this.props.setLoginError(false);
    this.props.inviteUser({
      email: this.state.email,
      user_id: this.props.user.id,
      company_id: this.props.company.id
    }, () => { this.props.onSuccess()});
  }

  handleEmailChange(event) {
    const value = event.target.value.replace(/\s/g,'').toLowerCase();
    this.setState({ email: value });
  }

  renderLoginError() {
    if (this.props.login_error != null) {
      return (
        <div style={{ color: 'red', paddingTop: '20px' }}>
          {this.props.login_error}
        </div>
      );
    }
  }

  onCancel() {
    this.setState({ email: "" });
    this.props.onCancel();
  }

  render() {
    return (
      <Modal
        title="Add Collaborator"
        visible={this.props.visible}
        onOk={() => this.handleSubmit()}
        onCancel={() => this.onCancel()}
        okButtonProps={{ disabled: !this.shouldBeEnabled() }}
        okText="Submit"
      >
        <div>
          <label>
            Enter Email-Address: <br/>
            <Input style={{ width: '300px' }} type="text" value={this.state.email} onChange={(e) => this.handleEmailChange(e)} />
          </label>
        </div>
        {this.renderLoginError()}
      </Modal>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user, login_error } = state.account;
  const { company } = state.company;
  return { user, company, login_error };
}

export default connect(mapStatesToProps, {
  inviteUser,
  setLoginError
})(AddCollaborator);
