import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import '../../App.css';
import {
  setAccountMenu
} from '../../actions';
import logo from '../../images/mlogo.png';
import './Navbar.css';

class NavBar extends Component {

  constructor(props) {
    super(props);

    let current_path = props.history.location.pathname;
    if (current_path === "/") current_path = "rounds";

    console.log("current_path",  props.history.location.pathname);
    this.state = {
      isOpen: false,
      currentPath: current_path
    };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({ currentPath: location.pathname });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  isViewingPath(path) {
    return this.state.currentPath.includes(path);
  }

  renderBurgerMenu() {
    return (
      <div className="Navbar-mobile-top-menu">
        <Button
          icon="menu"
          type="link"
          onClick={() => this.setState({ isOpen: true })}
        />
        <img
          src={logo}
          alt="logo"
          style={{ width: "100px", marginLeft: "10px", cursor: "pointer" }}
          onClick={() => this.setState({ isOpen: true })}
        />
      </div>
    );
  }

  renderCloseButton() {
    return (
      <div className="Navbar-close">
        <Button
          icon="close"
          type="link"
          onClick={() => this.setState({ isOpen: false })}
        />
      </div>
    );
  }

  renderLinks() {
    let home_link = "/rounds";
    if (this.props.user.role === "MEETER") {
      return this.renderCloseButton();
    }

    if (this.props.user.role !== "MEETER") {
      return (
        <div>
          <div className={`Router-nav-link ${this.isViewingPath('round') || this.state.currentPath === "/" ? "Router-active-link" : ""}`}>
            <Link
              to={home_link}
              className="Navbar-nav-link"
              onClick={() => this.setState({ active_state: "Home" })}
            >
              Meeting Rounds
            </Link>
          </div>
          <div className={`Router-nav-link ${this.isViewingPath('members') ? "Router-active-link" : ""}`}>
            <Link
              to='/members'
              className="Navbar-nav-link"
              onClick={() => this.setState({ active_state: "Members" })}
            >
              Members ({this.props.company.num_members})
            </Link>
          </div>
          {this.renderCloseButton()}
        </div>
      );
    }
  }

  render() {
    console.log(this.props.history.location.pathname);

    return (
      <div>
        {this.renderBurgerMenu()}
        <div style={{ paddingTop: "8px", width: "200px" }} className={`Navbar-container ${this.state.isOpen ? "Navbar-container-open" : ""}`}>
          <nav onClick={() => {
            this.setState({ isOpen: false })
          }}>
            <img
              src={logo}
              alt="logo"
              style={{ width: "140px", marginTop: "20px", marginBottom: "30px", marginLeft: "16px", cursor: "pointer" }}
              onClick={() => this.props.history.push({ pathname: '/' })}
            />
            {this.renderLinks()}
          </nav>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user, show_account_menu } = state.account;
  const { company } = state.company;
  return { user, show_account_menu, company };
};

export default connect(mapStatesToProps, {
  setAccountMenu
})(NavBar);
