import React, { Component } from 'react';
import { Button, Modal } from 'antd';
import InviteLink from './InviteLink';
import './InviteBanner.css';

class InviteBanner extends Component {

  state = {
    visible: false,
    copied: false
  }

  renderModal() {
    return (
      <Modal
          title="Invite Team"
          visible={this.state.visible}
          footer={null}
          onCancel={() => this.setState({ visible: false })}
          width="700px"
          titleStyle={{ fontFamily: "IBM Plex Mono" }}
        >
          <h3> You can invite your team with the following link. </h3>
          <InviteLink
            join_url={this.props.join_url}
            copied={this.state.copied}
            onCopy={() => this.setState({ copied: true })}
          />
        </Modal>
    );
  }

  render() {
    return (
      <div>
        <div className="InviteBanner-container">
          <div className="InviteBanner-text-container">
            <div style={{ flexGrow: 1 }}>
              <div className="InviteBanner-header">Invite your Team</div>
              <div>Invite your team so they can partipate in future meeting rounds</div>
            </div>
            <div className="InviteBanner-button-container">
              <Button style={{backgroundColor: 'rgba(0,0,0,0.65)', border: 'none', color: "#04eaa6" }} onClick={() => this.setState({ visible: true, copied: false })}>
                Invite Team
              </Button>
            </div>
          </div>
        </div>
        {this.renderModal()}
      </div>
    )
  }
}

export default InviteBanner;
