import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import PopUpForm from '../../../common/PopUpForm';
// import CheckoutForm from './CheckoutForm';
import StyledCheckout from './StyledCheckout';
// import { config } from '../../../global_vars';

class EnterCardContainer extends Component {
  render() {
    return null;
    // return (
    //   <PopUpForm onCancel={() => this.props.onCancel()} backgroundColor="#f5f5f7" minWidth="600px" alignItems="space-between">
    //     <StripeProvider apiKey={config.stripe_public_key}>
    //       <div className="example">
    //         <Elements>
    //           <StyledCheckout onSuccess={() => this.props.onSuccess()}/>
    //         </Elements>
    //       </div>
    //     </StripeProvider>
    //   </PopUpForm>
    // );
  }
}


export default EnterCardContainer;
