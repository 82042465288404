import React, { Component } from 'react';
import { connect } from 'react-redux';
import PopUpForm from './PopUpForm';
import DisableButton from './DisableButton';
import {
  setUserAndCompany,
  postRequest
} from '../actions';

class CheckEmailUpdate extends Component {
  state = {
    fetchedValidation: false,
    error_message: null
  }

  componentDidMount() {
    this.checkValidToken();
  }

  checkValidToken() {
    console.log("checking token");
    this.props.postRequest('/validate_new_email', { token: this.props.token }, (json) => {
      console.log(json);
      if (json.success) {
        this.props.setUserAndCompany(json.user);
        this.setState({
          success_message: `Your email was successfully updated to ${json.user.email}.`,
          fetchedValidation: true
        });
      } else {
        this.setState({ error_message: json.reason });
      }
    });
  }

  renderContent() {
    if (this.state.error_message) {
      return (
        <div>
          {this.state.error_message}
        </div>
      );
    } else if (this.state.fetchedValidation) {
      return (
        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
          <div>{this.state.success_message}</div>
          <br/>
          <DisableButton
            label="OK"
            onClick={() => this.props.onCancel()}
            marginTop="32px"
            minWidth="300px"
          />
        </div>
      );
    } else {
      return (
        <div>
          <div className="loader" />
        </div>
      );
    }
  }

  render() {
    console.log("token", this.props.token);
    return (
      <PopUpForm hideCancel backgroundColor="#f5f5f7" minHeight="160px">
        {this.renderContent()}
      </PopUpForm>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user } = state.account;
  return { user };
};

export default connect(mapStatesToProps, {
  setUserAndCompany,
  postRequest
})(CheckEmailUpdate);
