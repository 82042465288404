const COMPANY = function (data) {
  this.id = data.id;
  this.name = data.name;
  this.van_url = data.van_url;
  this.collaborators = data.collaborators;
  this.card_info = data.card_info;
  this.subscription_info = data.subscription_info;
  this.has_access = data.has_access;
  this.subscribe_state = data.subscribe_state;
  this.upcoming_rounds = data.upcoming_rounds;
  this.past_rounds = data.past_rounds;
  this.locations = data.locations;
  this.num_members = data.num_members;
  this.join_url = data.join_url;
  this.departments = data.departments
  this.cal_manager_id = data.cal_manager_id
};

export default COMPANY;
