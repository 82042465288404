import React, { Component } from 'react';
import { Steps, Button, Input, Card, List } from 'antd';
import { connect } from 'react-redux';
import BusinessHours from './BusinessHours';
import InviteLink from './InviteLink';
import './JoinCompany.css';
import {
  createCompany,
  setUserAndCompany,
  setToHomePage
} from '../../actions';

const { Step } = Steps;

class StepForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      group_name: "",
      show_business_button: false,
      selected_days: ["Mon", "Tue", "Wed", "Thu", "Fri"],
      start_time: "",
      end_time: "",
      company_json: null,
      join_url: "",
      copied: false,
      departments: [],
      departmentToAdd: ""
    };
  }

  getSteps() {
    return [
      {
        title: 'Create Group',
        content: this.renderNameForm(),
      },
      {
        title: 'Departments',
        content: this.renderDepartments(),
      },
      {
        title: 'Get Invite Link',
        content: this.renderJoinUrl(),
      },
    ];
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  renderNameForm() {
    return (
      <div className="JoinCompany-steps-name-form-container">
        <div style={{ width: "100%", maxWidth: "400px" }}>
          <div className="JoinCompany-steps-field-header">Enter your group or company name</div>
          <Input
            value={this.state.group_name}
            placeholder="XYZ Co."
            onChange={(e) => this.setState({ group_name: e.target.value })}
            size="large"
          />
        </div>
      </div>
    );
  }

  renderDepartments() {
    return (
      <div className="JoinCompany-steps-business-container">
        <div>
          <div className="JoinCompany-steps-field-header" style={{ maxWidth: 400 }}>Add departments within your company your group. This allows us to make connections accross departments.</div>

          {
            <List
              style={{ width: "100%", maxHeight: 210, maxWidth: 400, height: "100%", overflow: "auto" }}
              dataSource={this.state.departments}
              header={
                <div style={{ display: "flex" }}>
                  <Input
                    size="large"
                    style={{ marginRight: 10 }}
                    value={this.state.departmentToAdd}
                    onChange={(e) => this.setState({ departmentToAdd: e.target.value })}
                    placeholder="Enter Department Name"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        this.setState({ departments: [{name: this.state.departmentToAdd}].concat(this.state.departments), departmentToAdd: "" });
                      }
                    }}
                  />
                  <Button size="large" onClick={() => this.state.departmentToAdd.length <= 0 ? {} : this.setState({ departments: [{name: this.state.departmentToAdd}].concat(this.state.departments), departmentToAdd: "" })} type="primary">+ Add</Button>
                </div>
              }
              locale={{ emptyText: " " }}
              renderItem={(item, index) => {
                return (
                  <List.Item>
                    <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                      <div style={{ flexGrow: 1 }}>
                        {item.name}
                      </div>
                      <div>
                        <Button
                          type="link"
                          icon="close"
                          onClick={() => {
                            let departments = this.state.departments
                            departments.splice(index, 1);
                            this.setState({ departments: departments });
                          }}
                        />
                      </div>
                    </div>
                  </List.Item>
                )
              }}
            />
          }
        </div>
      </div>
    );
  }

  renderJoinUrl() {
    return (
      <div className="JoinCompany-steps-joinurl-container">
        <h2>You're all set!</h2>
        <h4>You can invite your members of your company to join with the following link. They will be included in future MeetLoaf rounds. You can always access the link below on your home page.</h4>
        <InviteLink
          join_url={this.state.join_url}
          copied={this.state.copied}
          onCopy={() => this.setState({ copied: true })}
        />
      </div>
    );
  }

  shouldBusinessEnable() {
    return this.state.selected_days.length > 0 && this.state.start_time !== "" && this.state.end_time !== "";
  }

  onCreateCompany() {
    this.setState({ is_loading: true });
    const data = {
      user_id: this.props.user.id,
      name: this.state.group_name,
      user_days: this.state.selected_days,
      user_start_time: this.state.start_time,
      user_end_time: this.state.end_time,
      departments: this.state.departments
    };
    this.props.createCompany(data, (json) => {
      console.log("json from step create co", json);
      this.setState({ company_json: json, join_url: json.join_url });
      this.next();
    });
  }

  onDonePressed() {
    this.props.setUserAndCompany(this.state.company_json.user);
    this.props.setToHomePage(this.state.company_json)
  }

  getButton() {
    const { current } = this.state;
    if (current === 0) {
      return (
        <Button type="primary" disabled={this.state.group_name === ""} onClick={() => this.next()}>
          Next
        </Button>
      );
    } else if (current === 1) {
      return (
        <Button type="primary"
          loading={this.state.is_loading}
          onClick={() => this.onCreateCompany()}
        >
          Next
        </Button>
      );
    } else {
      return (
        <Button type="primary" onClick={() => this.onDonePressed()}>
          Done
        </Button>
      );
    }
  }

  render() {
    const { current } = this.state;
    return (
      <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
          <Steps current={current}>
            {this.getSteps().map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        <div className="steps-content" style={{ height: "100%", width: "100%" }}>
          {this.getSteps()[current].content}
        </div>
        <div className="JoinCompany-steps-footer-container">
          {this.getButton()}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  const { user } = state.account;
  return { user };
};

export default connect(mapStatesToProps, {
  createCompany,
  setUserAndCompany,
  setToHomePage
})(StepForm);
