import React, { Component } from 'react';
import axios from 'axios';
import { Spin, Table } from 'antd';
import Media from "react-media";
import './ViewRound.css';

const pageSize = 20;

class ViewRound extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchedInfo: false,
      meeting_title: "",
      meeting_description: "",
      start_date: "",
      end_date: "",
      meetings: [],
      perc_matched: 0.0
    }
  }

  componentDidMount() {
    this.getRoundInfo();
  }

  getRoundInfo() {
    axios.get("/get_round_info", {
      params: {
        round_id: this.props.match.params.id
      }
    })
    .then(response => response.data)
    .then(json => {
      if (json.success) {
        const { meetings, perc_matched, start_date, end_date, meeting_title, meeting_description } = json.round_info;
        this.setState({
          fetchedInfo: true,
          meetings, perc_matched, start_date, end_date, meeting_title, meeting_description
        });
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  getColumns(isLarge) {
    let columns = [
      {
        title: 'Participants',
        dataIndex: 'participants',
        key: 'participants',
        width: 350,
        render: (text, record) => {
          return record.link ? <div><a target="_blank" href={record.link}>{text}</a></div> : <div>{text}</div>;
        }
      }];
      if (isLarge) {
        columns = columns.concat([
          {
            title: 'Date + Time',
            dataIndex: 'datetime',
            key: 'datetime',
            render(text, record) {
              return {
              	children: <div>{text}</div>,
              };
            }
          }
        ])
      }

      // {
      //   title: 'Location',
      //   dataIndex: 'location',
      //   key: 'location',
      //   render(text, record) {
      //     return {
      //     	children: <div>{text}</div>,
      //     };
      //   }
      // }
    return columns;
  }

  renderMeetings(isLarge) {
    let data_array = [];

    this.state.meetings.forEach((r) => {
      const r_obj = {
        key: r.id,
        participants: r.meeters,
        datetime: r.meet_time,
        location: r.location,
        link: r.link
      };
      data_array.push(r_obj)
    });
    return (
      <Table
        columns={this.getColumns(isLarge)}
        dataSource={data_array}
        pagination={data_array.length > pageSize && { pageSize }}
        style={{ maxWidth: "1020px" }}
      />
    );
  }

  renderHeader() {
    return (
      <div>
        <h2>{this.state.meeting_title} - {this.state.start_date} - {this.state.end_date}</h2>
        <h4>{this.state.perc_matched} were matched to a meeting</h4>
      </div>
    )
  }

  renderContent(isLarge) {
    return (
      <div style={{ width: "100%" }}>
        {this.renderHeader()}
        <div className="ViewRound-table-container">
          {this.renderMeetings(isLarge)}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.fetchedInfo) {
      return (
        <Media query="(min-width: 599px)">
          {matches => {
            return(
              <div className="ViewRound-container">
                {this.renderContent(matches)}
              </div>
            );
          }}
        </Media>
      )
    } else {
      return (
        <div className="ViewRound-spinner">
          <Spin size="large" />
        </div>
      );
    }
  }
}

export default ViewRound;
