import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
// import Home from '../Home';
import Logout from '../auth/Logout';
import CalendarRead from '../auth/CalendarRead';
import TestRoute from '../TestRoute';
import NotFound from '../../common/NotFound';
import VerifyEmail from '../../common/VerifyEmail';
import NavBar from './navbar';
import ShowVerify from '../auth/ShowVerify';
import ShowInvite from '../auth/ShowInvite';
import JoinCompany from '../company/JoinCompany';
import Rounds from '../rounds/Rounds';
import ViewRound from '../rounds/ViewRound';
import CreateRound from '../rounds/CreateRound';
import AccountSettings from '../settings/account/AccountSettings';
import CompanySettings from '../settings/company/CompanySettings';
import PaymentSettings from '../settings/payments/PaymentSettings';
import SetUpBusinessHours from '../company/SetUpBusinessHours';
import Members from '../company/Members';
import AccountMenu from './AccountMenu';
import './Router.css';

class RouterComponent extends Component {

  // check invited before showing join company
  renderExtra() {
    if (!window.location.pathname.includes("ver_em")) {
      if (!this.props.user.has_verified_email) {
        return <ShowVerify user={this.props.user} />;
      } else if (this.props.user.has_invite) {
        return <ShowInvite user={this.props.user} />;
      } else if (!this.props.user.has_joined_company) {
        return <JoinCompany user={this.props.user} />;
      } else if (!this.props.user.has_set_business_hours) {
        return (
          <SetUpBusinessHours
            user={this.props.user}
            closable={false}
            onCancel={() => {}}
            visible
            selected_days={this.props.user.available_days}
            start_time={this.props.user.day_start}
            end_time={this.props.user.day_end}
            joinDate={this.props.user.joinDate}
            birthdate={this.props.user.birthdate}
            department={this.props.user.department}
            time_zone={this.props.user.time_zone}
          />
        );
      } else if (this.props.user.current_scope !== "READ" && this.props.user.current_scope !== "WRITE") {
        return (
          <CalendarRead />
        );
      }
    }
  }

  renderNavBar() {
    if (!window.location.pathname.includes("ver_em")) {
      return <NavBar history={this.props.history}/>;
    }
  }

  renderContent() {
    if (this.props.user.has_joined_company) {
      return (
        <Router history={this.props.history}>
          <div className="Router-wrapper">
            <div className="Router-desktop-padder"> </div>
            <AccountMenu />
            {this.renderNavBar()}
            <div className="Router-main-area">
              <Switch>
                <Route path='/' exact component={Rounds} />
                <Route path='/login' component={Rounds} />
                <Route path='/login/:invite_hash' component={Rounds} />
                <Route path='/register/:invite_hash' component={Rounds} />
                <Route path='/account_settings' component={AccountSettings} />
                <Route path='/company_settings' component={CompanySettings} />
                <Route path='/payment_settings' component={PaymentSettings} />
                <Route path='/rounds' component={Rounds} />
                <Route path='/view_round/:id' component={ViewRound} />
                <Route path='/create_round' component={CreateRound} />
                <Route path='/logout' component={Logout} />
                <Route path='/test_route' component={TestRoute} />
                <Route path="/ver_em/:token" component={VerifyEmail}/>
                <Route path="/up_em/:token" component={AccountSettings}/>
                <Route path="/members" component={Members}/>
                <Route path='/join/:join_hash' component={Rounds} />
                <Route component={NotFound} />
              </Switch>
            </div>
            <div className="Router-desktop-padder" style={{ background: "#fff" }}> </div>
          </div>
          {this.renderExtra()}
        </Router>
      );
    } else {
      return (
        <Router history={this.props.history}>
          <JoinCompany user={this.props.user} />
        </Router>
      );
    }
  }

  render() {
    return this.renderContent();
  }
}

const mapStatesToProps = (state) => {
  const { user, show_account_menu } = state.account;
  return { user, show_account_menu };
};

export default connect(mapStatesToProps, {})(RouterComponent);
